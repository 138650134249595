<app-header></app-header>
<br><br><br><br>
<section class="pt-5 pb-5 mb-5 d-table w-100" id="home">
  <div class="container">
    <div class="row mt-5 align-items-center justify-content-center">
      <div class="col-lg-6 col-md-7">
        <div class="title-heading" data-aos="fade-right" data-aos-duration="1000">
          <h1 class="heading mb-3"><span class="text-primary">myJuan Merchant</span></h1>
          <p class="text-muted int h6 mt-4 pg" data-aos="fade-up-right">myJuan Merchant is a mobile platform for attracting customers to your restaurant. A myJuan Merchant account will help your store get noticed and bring in orders. Creating your own platform will allow you to market more effectively.</p>
          <div class="d-flex mt-5 gap-2 text-center" data-aos="fade-up" data-aos-duration="1500">
            <!-- <a href="page-maintenance" class="btn btn-primary mt-2 me-2"><i class="uil uil-apple"></i> App Store</a> -->
            <a href="https://play.google.com/store/apps/details?id=com.vipco_mega_multi_wallet_merchant" class="btn btn-outline-primary mt-2 d-flex align-items-center gap-1 hov"><i class="uil uil-google-play"></i> Play Store</a>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-4 col-md-5 mt-4 pt-2 mt-sm-0 pt-sm-0">
        <div class="text-md-end text-center ms-lg-4 mt-3" data-aos="fade-left" data-aos-duration="1000">
          <img src="../../../../assets/myjuan/Merchant.png" alt="" width="450px">
        </div>
      </div>
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<section class="pt-5 pb-5 mb-5 d-table w-100">
  <div class="container position-relative"><br><br><br><br>
    <div class="blob1"></div>
    <div class="row mt-5 align-items-center ms-lg-2">
      <div class="col-lg-6 col-md-5 mt-4 pt-2 mt-sm-0 pt-sm-0">
        <div class="text-md-end text-center ms-lg-4" data-aos="fade-right" data-aos-duration="1000">
          <!--<img src="assets/images/app/vip88.svg" class="img-fluid app" alt="" width="500">-->
          <img src="assets/images/app/question_gif.gif" class="img-fluid app" alt="" width="500">
        </div>
      </div>
      <div class="col-lg-6 col-md-7">
        <div class="title-heading" data-aos="fade-right" data-aos-duration="1000">
          <h1 class="mb-3 text-primary text-left">What is myJuan Merchant?</h1>
          <p class="text-muted pg h6 int" data-aos="fade-up-right">myJuan Merchant is an online food delivery platform where you can create personalized restaurant profiles on our mobile app. It helps restaurants in increasing their online visibility. myJuan Merchant lists and promotes them through a variety of advertising campaigns. Customers can easily find their preferred restaurant by using filters such as cuisine type, delivery time, recent orders, and more. Ratings and reviews also give them an idea of a restaurant's quality.</p>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<section class="pt-5 pb-5 mb-5 d-table w-100">
  <div class="container position-relative"><br><br><br><br>
    <div class="blob"></div>
    <div class="row mt-5 align-items-center">
      <div class="col-lg-6 col-md-7">
        <div class="title-heading" data-aos="fade-right" data-aos-duration="1000">
          <h1 class="title mb-4 text-primary"><span class="text-primary">Why become a Partner Merchant?</span></h1><br>
          <p class="para-desc text-justify text-muted pg h6 int" data-aos="fade-up-right">We understand your needs and desires, which is why we simplified and streamlined the application to assist you in connecting with more customers in your community. We offer FREE registration and competitive commission. You can use the myJuan Merchant app to build and maintain relationships with your customers and learn how to connect with them more effectively. We offer a high-quality support system that is available 24 hours a day, seven days a week to help you grow your business.</p>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-6 col-md-5 mt-4 pt-2 mt-sm-0 pt-sm-0">
        <div class="text-md-end text-center ms-lg-4" data-aos="fade-right" data-aos-duration="1000">
          <img src="assets/images/app/partner.svg" class="img-fluid app" alt="" width="500">
        </div>
      </div>
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<br><br>

<section class="d-table w-100 mt-3 position-relative"><br><br>
  <div class="container">
    <div class="row mt-5 align-items-center">
      <div class="row justify-content-center">
        <div class="col-12 text-center">
          <div class="section-title mb-4 pb-2">
            <h4 class="title mb-4 text-primary text-center" data-aos="fade-up" data-aos-duration="1000">Accepted Merchant Store Type</h4>
          </div>
        </div>
        <!--end col-->
      </div>
      <div class="box">
        <div class="container">
           <div class="row justify-content-center">
              <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12" data-aos="fade-right">  
              <div class="box-part text-center itemss">         
                <i class="fa-solid fa-store" aria-hidden="true"></i>         
                <div class="title">
                  <h4 class="text-muted mt-2">Food Stall</h4>
                </div>           
               </div>
            </div>

            <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12" data-aos="fade-up">  
              <div class="box-part text-center itemss">         
                <i class="fa-solid fa-utensils"></i>        
                <div class="title">
                  <h4 class="text-muted mt-2">Eatery</h4>
                </div>            
               </div>
            </div>

            <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12" data-aos="fade-left">  
              <div class="box-part text-center itemss">         
                <i class="fa-solid fa-shop"></i>       
                <div class="title">
                  <h4 class="text-muted mt-2">Physical Store</h4>
                </div>            
               </div>
            </div>

            <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12" data-aos="fade-right">  
              <div class="box-part text-center itemss">         
                <i class="fa-solid fa-wine-glass-empty"></i>      
                <div class="title">
                  <h4 class="text-muted mt-2">Restaurant</h4>
                </div>            
               </div>
            </div>

            <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12" data-aos="fade-up">  
              <div class="box-part text-center itemss">         
                <i class="fa-solid fa-burger"></i>        
                <div class="title">
                  <h4 class="text-muted mt-2">Street Food Vendors</h4>
                </div>            
               </div>
            </div>

            <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12" data-aos="fade-left">  
              <div class="box-part text-center itemss">         
                <i class="fa-solid fa-house-circle-check"></i>      
                <div class="title">
                  <h4 class="text-muted mt-2">Home-based Food Vendor</h4>
                </div>            
               </div>
            </div>

            <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12" data-aos="fade-right">  
              <div class="box-part text-center itemss">         
                <i class="fa-solid fa-mug-saucer"></i>       
                <div class="title">
                  <h4 class="text-muted mt-2">Cafe</h4>
                </div>            
               </div>
            </div>

            <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12" data-aos="fade-up">  
              <div class="box-part text-center itemss">         
                <i class="fa-brands fa-cc-diners-club"></i>       
                <div class="title">
                  <h4 class="text-muted mt-2">Diner</h4>
                </div>            
               </div>
            </div>

            <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12" data-aos="fade-left">  
              <div class="box-part text-center itemss">         
                <i class="fa-solid fa-person-booth"></i>        
                <div class="title">
                  <h4 class="text-muted mt-2">Catering</h4>
                </div>            
               </div>
            </div>


        </div>		
        </div>
    </div>
      
      <!--end col-->

      <div class="col-lg-6 col-md-5 mt-4 pt-2 mt-sm-0 pt-sm-0">
        <div class="text-md-end text-center ms-lg-4" data-aos="fade-left" data-aos-duration="1000">
          <!--<img src="assets/images/app/vips_app1-.png" class="img-fluid app" alt="" width="400">-->
        </div>
      </div>
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<br><br>
<section class="pt-5 pb-5 mb-5 d-table w-100">
  <div class="container position-relative">
    <div class="blob3"></div>
    <div class="row mt-5 align-items-center justify-content-left">
      <div class="col-lg-6 col-md-7">
        <div class="title-heading ms-lg-5" data-aos="fade-right" data-aos-duration="1000">
          <h1 class="title mb-4 text-primary"><span class="text-primary">Requirements</span></h1>
          <ul class="list-unstyled text-muted">
            <li class="mb-0 h5"><span class="text-primary h5 me-2"><i
                  class="uil uil-check-circle align-middle"></i></span>Age 18 years old and above</li><br>
            <li class="mb-0 h5"><span class="text-primary h5 me-2"><i
                  class="uil uil-check-circle align-middle"></i></span>Smartphone Device</li><br>
            <li class="mb-0 h5"><span class="text-primary h5 me-2"><i
                  class="uil uil-check-circle align-middle"></i></span>One valid government ID</li><br>
            <li class="mb-0 h5"><span class="text-primary h5 me-2"><i
                  class="uil uil-check-circle align-middle"></i></span>Business Name</li><br>
                  <li class="mb-0 h5"><span class="text-primary h5 me-2"><i
                    class="uil uil-check-circle align-middle"></i></span>Business Address</li><br>
              <li class="mb-0 h5"><span class="text-primary h5 me-2"><i
                    class="uil uil-check-circle align-middle"></i></span>Store Photo</li><br>
              <li class="mb-0 h5"><span class="text-primary h5 me-2"><i
                    class="uil uil-check-circle align-middle"></i></span>Store Logo</li><br>
          </ul>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-4 col-md-5 mt-4 pt-2 mt-sm-0 pt-sm-0">
        <div class="text-md-end text-center ms-lg-4" data-aos="fade-left" data-aos-duration="1000">
          <img src="assets/images/app/req.svg" class="img-fluid app" alt="" width="500">
        </div>
      </div>
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>

<section class="pt-5 pb-5 mb-5 d-table w-100">
  <div class="container position-relative">
    <div class="blob4"></div>
    <div class="row mt-5 align-items-center gap-5">
      <div class="col-lg-5 col-md-5 mt-4 pt-2 mt-sm-0 pt-sm-0">
        <div class="text-md-end text-center ms-lg-2" data-aos="fade-left" data-aos-duration="1000">
          <img src="assets/images/app/req2.svg" class="img-fluid app" alt="" width="500">
        </div>
      </div>
      <div class="col-lg-5 col-md-7 ms-lg-5">
        <div class="title-heading" data-aos="fade-right" data-aos-duration="1000">
          <h1 class="title mb-4 text-primary"><span class="text-primary">Merchant Merch</span></h1>
          <ul class="list-unstyled text-muted ms-lg">
            <li class="mb-0 h5"><span class="text-primary h5 me-2">
              <i class="fa-solid fa-vest-patches align-middle"></i></span>1 Uniform (Optional)</li><br>
            <li class="mb-0 h5"><span class="text-primary h5 me-2">
              <i class="fa-regular fa-address-card align-middle"></i></span>myJuan ID</li><br>
            <li class="mb-0 h5"><span class="text-primary h5 me-2">
              <i class="fa-solid fa-qrcode align-middle"></i></span>Counter Standee QR code</li><br>
                  <li class="mb-0 h5"><span class="text-primary h5 me-2">
                    <i class="fa-solid fa-sheet-plastic align-middle"></i></span>Posters/Tarpaulin as Partner Merchant</li><br>
          </ul>
        </div>
      </div>
      <!--end col-->


    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<br><br>
<section class="pt-5 pb-5 mb-5 d-table w-100">
  <div class="container position-relative">
    <div class="blob5"></div>
    <div class="row mt-5 align-items-center">
      <div class="col-lg-6 col-md-7 ms-lg-4">
        <div class="title-heading" data-aos="fade-right" data-aos-duration="1000">
          <h1 class="title mb-4 text-primary"><span class="text-primary">Benefits / Advantages</span></h1>
          <ul class="list-unstyled text-muted">
            <li class="mb-0 h5"><span class="text-primary h5 me-2"><i
                  class="uil uil-check-circle align-middle"></i></span>Free registration and no hidden monthly fees</li><br>
            <li class="mb-0 h5"><span class="text-primary h5 me-2"><i
                  class="uil uil-check-circle align-middle"></i></span>Easy-to-use store setup within the app
                </li><br>
            <li class="mb-0 h5"><span class="text-primary h5 me-2"><i
                  class="uil uil-check-circle align-middle"></i></span>Reach more customers within your community</li><br>
            <li class="mb-0 h5"><span class="text-primary h5 me-2"><i
                  class="uil uil-check-circle align-middle"></i></span>Boost online visibility and save marketing costs</li><br>
            <li class="mb-0 h5"><span class="text-primary h5 me-2"><i
                  class="uil uil-check-circle align-middle"></i></span>Customize your store and products anytime you want</li><br>      
            <li class="mb-0 h5"><span class="text-primary h5 me-2"><i
                class="uil uil-check-circle align-middle"></i></span>Manage operations easier with myJuan Merchant app</li><br>
            <li class="mb-0 h5"><span class="text-primary h5 me-2"><i
                class="uil uil-check-circle align-middle"></i></span>Secured built-in wallet</li><br>
            <li class="mb-0 h5"><span class="text-primary h5 me-2"><i
                class="uil uil-check-circle align-middle"></i></span>Real-time earnings each time an order is completed</li><br>   
            <li class="mb-0 h5"><span class="text-primary h5 me-2"><i
              class="uil uil-check-circle align-middle"></i></span>You will earn 100% of the product price entered into the app</li><br>   
          </ul>
        </div>
      </div>
      <div class="col-lg-5 col-md-5 mt-4 pt-2 mt-sm-0 pt-sm-0">
        <div class="text-md-end text-center ms-lg-4" data-aos="fade-left" data-aos-duration="1000">
          <img src="assets/images/app/req3.svg" class="img-fluid app" alt="" width="300">
        </div>
      </div>
      <!--end col-->


    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>

<section class="section">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 text-center">
        <div class="title-heading mb-4 pb-2">
          <h4 class="title mb-4 text-primary h1" data-aos="fade-left" data-aos-duration="1000">How will you earn?</h4>
          <p class="text-muted para-desc mb-0 mx-auto h5" data-aos="fade-left" data-aos-duration="1000">We offer not just our service but also our hearts in everything we do. Start earning more with<span
              class="text-primary fw-bold"> myJuan</span> and let your business grow stronger and better.</p>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->

    <div class="row">
      <div class="col-lg-3 col-md-4 mt-4 pt-2" data-aos="fade-left" data-aos-duration="1000">
        <div
          class="card features fea-primary rounded p-4 bg-light text-center position-relative overflow-hidden border-0">
            <img class="img0" src="assets/images/app/accept_order.svg" alt="">
          <div class="card-body p-0 content">
            <h5 class="text-muted">Step 1:</h5>
            <h5 class="textarea-large">Accept Order Booking</h5>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-3 col-md-4 mt-4 pt-2" data-aos="fade-left" data-aos-duration="1000">
        <div
          class="card features fea-primary rounded p-4 bg-light text-center position-relative overflow-hidden border-0">
          <img class="img0" src="assets/images/app/cooking.svg" alt="">
          <div class="card-body p-0 content">
            <h5 class="text-muted">Step 2:</h5>
            <h5 class="textarea-large">Prepare the food</h5>
          </div>
          <span class="big-icon text-center">
            <i class="uil uil-money-bill"></i>
          </span>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-3 col-md-4 mt-4 pt-2" data-aos="fade-right" data-aos-duration="1000">
        <div
          class="card features fea-primary rounded p-4 bg-light text-center position-relative overflow-hidden border-0">
          <img class="img0" src="assets/images/app/rider.svg" alt="">
          <div class="card-body p-0 content">
            <h5 class="text-muted">Step 3:</h5>
            <h5 class="textarea-large">Deliver the Food</h5>
          </div>
          <span class="big-icon text-center">
            <i class="uil uil-clock-nine"></i>
          </span>
        </div>
      </div>
      
      <!--end col-->
      <div class="col-lg-3 col-md-4 mt-4 pt-2" data-aos="fade-right" data-aos-duration="1000">
        <div
          class="card features fea-primary rounded p-4 bg-light text-center position-relative overflow-hidden border-0">
          <img class="img0" src="assets/images/app/transfer.svg" alt="">
          <div class="card-body p-0 content">
            <h5 class="text-muted">Step 4:</h5>
            <h5 class="textarea-large">REAL-TIME Payment</h5>
          </div>
          <span class="big-icon text-center">
            <i class="uil uil-luggage-cart"></i>
          </span>
        </div>
      </div>

    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<section class="section">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 text-center">
        <div class="title mb-1 pb-2">
          <h5 class="title mb-2 text-primary h1" data-aos="fade-left" data-aos-duration="1000">Partner Merchant Application Process</h5>
          <p class="text-muted para-desc mb-0 mx-auto h6" data-aos="fade-up" data-aos-duration="1000">It's not difficult to partner up with myJuan Merchant. All you need to do is enter some basic information about your restaurant and you're ready to go.</p>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
    <h4 class="mb-0 text-primary h5 mt-2 text-center" data-aos="fade-up" data-aos-duration="1000">Steps to apply as a Partner Merchant:</h4>
    <div class="row justify-content-center">
      <div class="col-lg-4 col-md-4 mt-4 pt-2" data-aos="fade-right" data-aos-duration="1000">
        <div
          class="card rounded p-4  text-justify position-relative overflow-hidden border-0 align-items-center">
            <img class="img9" src="assets/images/app/down.svg" alt="">
          <div class="card-body p-0 content text-center">
            <h5 class="text-muted text-center">Step 1:</h5>
            <h5 class="textarea-large">Download myJuan Merchant from Google Play Store</h5>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-4 col-md-4 mt-4 pt-2" data-aos="fade-down" data-aos-duration="1000">
        <div
          class="card rounded p-4  text-justify position-relative overflow-hidden border-0 align-items-center">
          <img class="img9" src="assets/images/app/regist.svg" alt="">
          <div class="card-body p-0 content text-center">
            <h5 class="text-muted text-center">Step 2:</h5>
            <h5 class="textarea-large">Complete the Registration Process</h5>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-4 col-md-4 mt-4 pt-2" data-aos="fade-left" data-aos-duration="1000">
        <div
          class="card rounded p-4  text-justify position-relative overflow-hidden border-0 align-items-center">
          <img class="img9" src="assets/images/app/activate.svg" alt="">
          <div class="card-body p-0 content text-center">
            <h5 class="text-muted text-center">Step 3:</h5>
            <h5 class="textarea-large">To activate an account, scan/upload/enter the reference code generated from JuanPay or myJuan Operators</h5>
          </div>
        </div>
      </div>
      
      <!--end col-->
      <div class="col-lg-4 col-md-4 mt-4 pt-2" data-aos="fade-right" data-aos-duration="1000">
        <div
          class="card rounded p-4  text-justify position-relative overflow-hidden border-0 align-items-center">
          <img class="img9" src="assets/images/app/complete.svg" alt="">
          <div class="card-body p-0 content text-center">
            <h5 class="text-muted text-center">Step 4:</h5>
            <h5 class="textarea-large">Complete all Merchant requirements within the App</h5>
          </div>
        </div>
      </div>

      <div class="col-lg-4 col-md-4 mt-4 pt-2" data-aos="fade-up" data-aos-duration="1000">
        <div
          class="card rounded p-4  text-justify position-relative overflow-hidden border-0 align-items-center">
          <img class="img9" src="assets/images/app/clock.svg" alt="">
          <div class="card-body p-0 content text-center">
            <h5 class="text-muted text-center">Step 5:</h5>
            <h5 class="textarea-large">Allow 48 hours for your application to be approved</h5>
          </div>
        </div>
      </div>

      <div class="col-lg-4 col-md-4 mt-4 pt-2" data-aos="fade-left" data-aos-duration="1000">
        <div
          class="card rounded p-4  text-justify position-relative overflow-hidden border-0 align-items-center">
          <img class="img9" src="assets/images/app/finish.svg" alt="">
          <div class="card-body p-0 content text-center">
            <h5 class="text-muted text-center">Step 6:</h5>
            <h5 class="textarea-large">Create your menu and publish your store within the app</h5>
          </div>

        </div>
      </div>

    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<section class="pt-5 pb-5 mb-5 d-table w-100" data-aos="fade-up" data-aos-duration="1000">
  <div class="container">
  <div class="row d-flex justify-content-center">
    <div class="col-md-10 col-xl-8 text-center">
      <h3 class="mb-1 text-primary"data-aos="fade-left" data-aos-duration="1000">Testimonials</h3>
      <p class="mb-1 pb-1 mb-md-5 pb-md-0 text-muted"data-aos="fade-left" data-aos-duration="1000">
        Here are some of their thoughts on our myJuan Merchant app.
      </p>
    </div>
  </div>

  <div class="row text-center" data-aos="fade-up" data-aos-duration="1000">
    <div class="col-md-4 mb-5 mb-md-0">
      <div class="d-flex justify-content-center mb-4">
        <img src="\assets\images\app\cruz.png"
          class="rounded-circle shadow-1-strong shadow-lg" width="150" height="150" />
      </div>
      <h5 class="mb-3">Jessica Cruz</h5>
      <h6 class="text-primary mb-3">Merchant</h6>
      <p class="px-xl-3">
        <i class="fas fa-quote-left pe-2"></i>The myJuan Merchant app is the ideal platform for us to market our food to the rest of our community. It has an excellent platform for establishing an ideal ecosystem between hungry customers and food vendors. I give it five stars!
        <i class="fas fa-quote-right pe-2"></i>
      </p>
      <ul class="list-unstyled d-flex justify-content-center mb-0">
        <li>
          <i class="fas fa-star fa-sm text-warning"></i>
        </li>
        <li>
          <i class="fas fa-star fa-sm text-warning"></i>
        </li>
        <li>
          <i class="fas fa-star fa-sm text-warning"></i>
        </li>
        <li>
          <i class="fas fa-star fa-sm text-warning"></i>
        </li>
        <li>
          <i class="fas fa-star fa-sm text-warning"></i>
        </li>
      </ul>
    </div>
    <div class="col-md-4 mb-0">
      <div class="d-flex justify-content-center mb-4">
        <img src="\assets\images\app\dolor.png"
          class="rounded-circle shadow-1-strong shadow-lg" width="150" height="150" />
      </div>
      <h5 class="mb-3">Peter Santos</h5>
      <h6 class="text-primary mb-3">Merchant</h6>
      <p class="px-xl-3">
        <i class="fas fa-quote-left pe-2"></i>This app is incredible! You can order whatever you want whenever you want. It is always on time, and the riders are polite and friendly!
        <i class="fas fa-quote-right pe-2"></i>
      </p>
      <ul class="list-unstyled d-flex justify-content-center mb-0">
        <li>
          <i class="fas fa-star fa-sm text-warning"></i>
        </li>
        <li>
          <i class="fas fa-star fa-sm text-warning"></i>
        </li>
        <li>
          <i class="fas fa-star fa-sm text-warning"></i>
        </li>
        <li>
          <i class="fas fa-star fa-sm text-warning"></i>
        </li>
        <li>
          <i class="fas fa-star fa-sm text-warning"></i>
        </li>
      </ul>
    </div>
    <div class="col-md-4 mb-5 mb-md-0">
      <div class="d-flex justify-content-center mb-4">
        <img src="\assets\images\app\gelo.jpg"
          class="rounded-circle shadow-1-strong shadow-lg" width="150" height="150" />
      </div>
      <h5 class="mb-3">Michael Esguerra</h5>
      <h6 class="text-primary mb-3">Merchant</h6>
      <p class="px-xl-3">
        <i class="fas fa-quote-left pe-2"></i>I earn more using the myJuan Merchant app than I did with another food delivery app. I am also receiving more orders, which is hastening our daily business. The app's interface is very user-friendly, and their customer support is outstanding!
        <i class="fas fa-quote-right pe-2"></i>
      </p>
      <ul class="list-unstyled d-flex justify-content-center mb-0">
        <li>
          <i class="fas fa-star fa-sm text-warning"></i>
        </li>
        <li>
          <i class="fas fa-star fa-sm text-warning"></i>
        </li>
        <li>
          <i class="fas fa-star fa-sm text-warning"></i>
        </li>
        <li>
          <i class="fas fa-star fa-sm text-warning"></i>
        </li>
        <li>
          <i class="fas fa-star fa-sm text-warning"></i>
        </li>
      </ul>
    </div>
  </div>
</div>
</section>

<section class="pt-5 pb-5 mb-5 d-table w-100">
  <div class="container">
  <div class="row d-flex justify-content-center">
    <div class="row-md-10 col-xl-8 text-center">
      <h3 class="mb-4" data-aos="fade-left" data-aos-duration="1000">Join our <span class="text-primary"> myJuan</span> Community <br> as a Partner Merchant now!</h3>
    </div>
    <div class="d-flex mt-4 gap-2 justify-content-center text-center" data-aos="fade-up" data-aos-duration="1500">
      <!-- <a href="page-maintenance" class="btn btn-primary mt-2 me-2"><i class="uil uil-apple"></i> App Store</a> -->
      <a href="https://play.google.com/store/apps/details?id=com.vipco_mega_multi_wallet_merchant" class="btn btn-outline-primary mt-2 d-flex align-items-center gap-1 hov"><i class="uil uil-google-play"></i> Play Store</a>
    </div>
  </div>

</div>
</section>
<div class="position-relative">
  <div class="shape overflow-hidden text-footer">
    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
    </svg>
  </div>
</div>
<app-footer></app-footer> 