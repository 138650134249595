import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http'
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { HelpSupportRequestService } from './helpcenter-support-request.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-helpcenter-support-request',
  templateUrl: './helpcenter-support-request.component.html',
  styleUrls: ['./helpcenter-support-request.component.css'],
})

/**
 * Helpcenter Support Request Component
 */
export class HelpcenterSupportRequestComponent implements OnInit {
  hideFooter = true;
  constructor(
    private http : HttpClient,
    private fb: FormBuilder,
    private modalService: NgbModal,
    private _helpSupportRequestService: HelpSupportRequestService
    ) { }

  ngOnInit(): void {
  }

  get form() { return this.joinUsForm.controls; }

      joinUsForm = this.fb.group({
        fullname: ['', [Validators.required]],
        email: new FormControl('', [
          Validators.required,
          Validators.pattern(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/)
        ]),
        subject: ['', [Validators.required]],
        comments: ['', [Validators.required]]
      });
      isLoading:any =false;
      submitted:any = false;
      onSubmit(content): void {
        this.submitted = true;
        if (this.joinUsForm.valid) {
          this.isLoading = true;
          const data = {
            ...this.joinUsForm.value,
          };

          this._helpSupportRequestService.addRow(data,"MyJuanForAccountDeletion").subscribe(
            response => {
              // handle success case
              this.isLoading = false;

                // reset the form
                this.joinUsForm.reset();
                this.joinUsForm.markAsUntouched();
                this.joinUsForm.markAsPristine();

                this.openModal(content);
            },
            error => {
              this.isLoading = false;
            
              alert("error");
            }
          );
      }else{

     
      }
  }


  openModal(content: any): void {
    this.modalService.open(content, { centered: true, size: 'md' });
  }

    // openModal() {
    //   const modalRef = this.modalService.open(GeneralComponent, { ariaLabelledBy: 'modal-basic-title' });
    //   modalRef.componentInstance.message = "Thank You for Reaching Out!"; // Passing message to the child component
    //   modalRef.componentInstance.submessage = "We've received your message and appreciate you contacting us. One of our team members will review your inquiry and get back to you as soon as possible. Meanwhile, feel free to check out our latest updates and stay connected with us."; // Passing message to the child component
      
    //   modalRef.result.then((result) => {
    //     console.log(`Closed with: ${result}`);
    //   }, (reason) => {
    //     console.log(`Dismissed ${this.getDismissReason(reason)}`);
    //   });
    // }

    // private getDismissReason(reason: any): string {
    //   if (reason === 'ESC') {
    //     return 'by pressing ESC';
    //   } else if (reason === 'BACKDROP_CLICK') {
    //     return 'by clicking on a backdrop';
    //   } else {
    //     return  `with: ${reason}`;
    //   }
    // }
    
}

function send(arg0: { Host: string; Username: string; Password: string; To: string; From: string; Subject: string; Body: string; }) {
  throw new Error('Function not implemented.');
}


