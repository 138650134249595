
import { HttpClient, } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({providedIn: 'root'})
export class  HelpSupportRequestService
{
    private _data: BehaviorSubject<any> = new BehaviorSubject(null);

   
    private baseUrl = 'https://vips-czn.azurewebsites.net/api/v2/';
    constructor(private _httpClient: HttpClient)
    {

    }
    addRow(data: any, sheet : any): Observable<any> {
      const headers = { 
        'Content-Type': 'application/json'
      };

      const dataRequest : any = 
      {
          "values": this.getFormValuesAsArray(data),
          "sheetid": "1emOvKs5YEOqGqCraDj4ExOqzCKzu-1Xv4Ax-dWYg51I",
          "sheetname" : sheet
      };

      return this._httpClient.post<any>(`${this.baseUrl}gsheet/add_row?code=GkZbeLVTUJe5gTqNdaJ5ynujwdZwGMT4s2YN1wG0Um9BAzFuHK8j9Q%3D%3D`, dataRequest, { headers });
    }
    
    getFormValuesAsArray(data : any) {
      const formValues = data; // This will get the current values of the form
      const valuesArray = Object.values(formValues); // Convert object values to an array
      return valuesArray;
    }

}
