<!-- Navbar STart -->
<header id="topnav" class="defaultscroll sticky" (window:scroll)="windowScroll()" [class]="navClass"
  [ngClass]="{'tagline-height': sliderTopbar == true}">
  <div class="container">
    <!-- Logo container-->
    <div>
      <a (click)="toHome()" class="logo" routerLink="/index-apps.components" *ngIf="navClass !== 'nav-light'">
        <img src="../../../assets/myjuan/myJuan logo_landscape.png" class="l-dark logo-light-mode" height="70px" alt="">
        <img src="../../../assets/myjuan/myJuan logo_landscape.png" class="logo-dark-mode" height="70px" alt="">
      </a>

      <ul class="buy-button list-inline mb-0" *ngIf="shopPages == true">
        <li class="list-inline-item mb-0">
          <div class="dropdown" ngbDropdown>
            <button type="button" class="btn btn-link text-decoration-none dropdown-toggle p-0 pe-2" ngbDropdownToggle
              data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <i class="uil uil-search h5 text-muted"></i>
            </button>
            <div class="dropdown-menu dd-menu dropdown-menu-end bg-white shadow rounded border-0 mt-3 py-0"
              style="width: 300px;" ngbDropdownMenu>
              <form>
                <input type="text" id="text" name="name" class="form-control border bg-white" placeholder="Search...">
              </form>
            </div>
          </div>
        </li>
        <li class="list-inline-item mb-0 pe-1">
          <div class="dropdown" ngbDropdown>
            <button type="button" class="btn btn-icon btn-soft-primary dropdown-toggle" ngbDropdownToggle data-bs-toggle="dropdown"
              aria-haspopup="true" aria-expanded="false"><i
                class="uil uil-shopping-cart align-middle icons"></i></button>
            <div class="dropdown-menu dd-menu dropdown-menu-end bg-white shadow rounded border-0 mt-3 p-4" ngbDropdownMenu
              style="width: 300px;">
              <div class="pb-4">
                <a href="javascript:void(0)" class="d-flex align-items-center">
                  <img src="assets/images/shop/product/s-1.jpg" class="shadow rounded" style="max-height: 64px;" alt="">
                  <div class="flex-1 text-start ms-3">
                    <h6 class="text-dark mb-0">T-shirt (M)</h6>
                    <p class="text-muted mb-0">$320 X 2</p>
                  </div>
                  <h6 class="text-dark mb-0">$640</h6>
                </a>

                <a href="javascript:void(0)" class="d-flex align-items-center mt-4">
                  <img src="assets/images/shop/product/s-2.jpg" class="shadow rounded" style="max-height: 64px;" alt="">
                  <div class="flex-1 text-start ms-3">
                    <h6 class="text-dark mb-0">Bag</h6>
                    <p class="text-muted mb-0">$50 X 5</p>
                  </div>
                  <h6 class="text-dark mb-0">$250</h6>
                </a>

                <a href="javascript:void(0)" class="d-flex align-items-center mt-4">
                  <img src="assets/images/shop/product/s-3.jpg" class="shadow rounded" style="max-height: 64px;" alt="">
                  <div class="flex-1 text-start ms-3">
                    <h6 class="text-dark mb-0">Watch (Men)</h6>
                    <p class="text-muted mb-0">$800 X 1</p>
                  </div>
                  <h6 class="text-dark mb-0">$800</h6>
                </a>
              </div>

              <div class="d-flex align-items-center justify-content-between pt-4 border-top">
                <h6 class="text-dark mb-0">Total($):</h6>
                <h6 class="text-dark mb-0">$1690</h6>
              </div>

              <div class="mt-3 text-center">
                <a href="javascript:void(0)" class="btn btn-primary me-2">View Cart</a>
                <a href="javascript:void(0)" class="btn btn-primary">Checkout</a>
              </div>
              <p class="text-muted text-start mt-1 mb-0">*T&amp;C Apply</p>
            </div>
          </div>
        </li>
        <li class="list-inline-item mb-0 pe-1">
          <a href="javascript:void(0);" class="btn btn-icon btn-soft-primary" (click)="wishListModal(content)"><i
              class="uil uil-heart align-middle icons"></i></a>
        </li>

        <ng-template #content>
          <div class="modal-content rounded shadow-lg border-0 overflow-hidden">
            <div class="modal-body py-5">
              <div class="text-center">
                <div class="icon d-flex align-items-center justify-content-center bg-soft-danger rounded-circle mx-auto"
                  style="height: 95px; width:95px;">
                  <h1 class="mb-0"><i class="uil uil-heart-break align-middle"></i></h1>
                </div>
                <div class="mt-4">
                  <h4>Your wishlist is empty.</h4>
                  <p class="text-muted">Create your first wishlist request...</p>
                  <div class="mt-4">
                    <a href="javascript:void(0)" class="btn btn-outline-primary">+ Create new wishlist</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-template>

        <li class="list-inline-item mb-0">
          <div class="dropdown dropdown-primary" ngbDropdown>
            <button type="button" class="btn btn-icon btn-soft-primary dropdown-toggle" ngbDropdownToggle
              data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i
                class="uil uil-user align-middle icons"></i></button>
            <div class="dropdown-menu bg-white shadow rounded border-0 mt-3 py-3"
              ngbDropdownMenu style="width: 200px;">
              <a class="dropdown-item text-dark" href="#"><i class="uil uil-user align-middle me-1"></i> Account</a>
              <a class="dropdown-item text-dark" href="#"><i class="uil uil-clipboard-notes align-middle me-1"></i>
                Order History</a>
              <a class="dropdown-item text-dark" href="#"><i class="uil uil-arrow-circle-down align-middle me-1"></i>
                Download</a>
              <div class="dropdown-divider my-3 border-top"></div>
              <a class="dropdown-item text-dark" href="#"><i class="uil uil-sign-out-alt align-middle me-1"></i>
                Logout</a>
            </div>
          </div>
        </li>
      </ul>

      <ul class="buy-button list-inline mb-0" *ngIf="buttonList == true">
        <li class="list-inline-item mb-0 ms-1">
          <div class="dropdown">
            <button type="button" class="btn btn-link text-decoration-none dropdown-toggle p-0 pe-2"
              data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <i class="uil uil-search text-muted"></i>
            </button>
            <div class="dropdown-menu dd-menu dropdown-menu-end bg-white shadow rounded border-0 mt-3 py-0"
              style="width: 300px;">
              <form>
                <input type="text" id="text" name="name" class="form-control border bg-white" placeholder="Search...">
              </form>
            </div>
          </div>
        </li>
        <li class="list-inline-item mb-0 ms-1">
          <a href="javascript:void(0)" class="btn btn-icon btn-primary"><i class="uil uil-facebook-f icons"></i></a>
        </li>
        <li class="list-inline-item mb-0 ms-1">
          <a href="javascript:void(0)" class="btn btn-icon btn-primary"><i class="uil uil-github icons"></i></a>
        </li>
        <li class="list-inline-item mb-0 ms-1">
          <a href="javascript:void(0)" class="btn btn-icon btn-primary"><i class="uil uil-twitter icons"></i></a>
        </li>
        <li class="list-inline-item mb-0 ms-1">
          <a href="javascript:void(0)" class="btn btn-icon btn-primary"><i class="uil uil-linkedin-alt icons"></i></a>
        </li>
      </ul>

      <ul class="buy-button list-inline mb-0" *ngIf="isdeveloper == true">
        <li class="list-inline-item mb-0">
          <div class="dropdown">
            <button type="button" class="btn btn-link text-decoration-none dropdown-toggle p-0 pe-2"
              data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <i class="uil uil-search text-muted"></i>
            </button>
            <div class="dropdown-menu dd-menu dropdown-menu-end bg-white shadow rounded border-0 mt-3 py-0"
              style="width: 300px;">
              <form>
                <input type="text" id="text2" name="name" class="form-control border bg-white" placeholder="Search...">
              </form>
            </div>
          </div>
        </li>
        <li class="list-inline-item mb-0 pe-1">
          <a href="#" class="btn btn-icon btn-soft-primary"><i class="uil uil-github icons"></i></a>
        </li>
        <li class="list-inline-item mb-0 pe-1">
          <a href="#" class="btn btn-icon btn-soft-primary"><i class="mdi mdi-stack-overflow mdi-18px icons"></i></a>
        </li>
        <li class="list-inline-item mb-0">
          <a href="javascript:void(0)" class="btn btn-icon btn-soft-primary" (click)="developerModal(content)"
            data-bs-toggle="modal" data-bs-target="#loginform"><i class="uil uil-user icons"></i></a>
        </li>
        <ng-template #content let-modal>
          <div class="modal-lg modal-dialog-centered" role="document">
            <div class="modal-content rounded shadow-lg border-0 overflow-hidden position-relative">
              <button type="button" class="btn-close position-absolute top-0 end-0 mt-2 me-2"
                (click)="modal.dismiss('Cross click')" style="z-index: 1" data-bs-dismiss="modal"
                aria-label="Close"></button>
              <div class="modal-body p-0">
                <div class="container-fluid px-0">
                  <div class="row align-items-center g-0">
                    <div class="col-lg-6 col-md-5">
                      <img src="assets/images/course/online/ab02.jpg" class="img-fluid" alt="">
                    </div>
                    <!--end col-->

                    <div class="col-lg-6 col-md-7">
                      <form class="login-form p-4">
                        <div class="row">
                          <div class="col-lg-12">
                            <div class="mb-3">
                              <label class="form-label">Your Email <span class="text-danger">*</span></label>
                              <div class="form-icon position-relative">
                                <i-feather name="user" class="fea icon-sm icons"></i-feather>
                                <input type="email" class="form-control ps-5" placeholder="Email" name="email"
                                  required="">
                              </div>
                            </div>
                          </div>
                          <!--end col-->

                          <div class="col-lg-12">
                            <div class="mb-3">
                              <label class="form-label">Password <span class="text-danger">*</span></label>
                              <div class="form-icon position-relative">
                                <i-feather name="key" class="fea icon-sm icons"></i-feather>
                                <input type="password" class="form-control ps-5" placeholder="Password" required="">
                              </div>
                            </div>
                          </div>
                          <!--end col-->

                          <div class="col-lg-12">
                            <div class="d-flex justify-content-between">
                              <div class="mb-3">
                                <div class="custom-control custom-checkbox">
                                  <input type="checkbox" class="custom-control-input" id="customCheck1">
                                  <label class="custom-control-label ms-1" for="customCheck1">Remember me</label>
                                </div>
                              </div>
                              <p class="forgot-pass mb-0"><a routerLink="/auth-re-password"
                                  class="text-dark fw-bold">Forgot password ?</a></p>
                            </div>
                          </div>
                          <!--end col-->

                          <div class="col-lg-12 mb-0">
                            <div class="d-grid">
                              <button class="btn btn-primary">Sign in</button>
                            </div>
                          </div>
                          <!--end col-->

                          <div class="col-12 text-center">
                            <p class="mb-0 mt-3"><small class="text-dark me-2">Don't have an account ?</small> <a
                                routerLink="/auth-signup" class="text-dark fw-bold">Sign Up</a></p>
                          </div>
                          <!--end col-->
                        </div>
                        <!--end row-->
                      </form>
                    </div>
                    <!--end col-->
                  </div>
                  <!--end row-->
                </div>
                <!--end container-->
              </div>
            </div>
          </div>
        </ng-template>
      </ul>

      <a class="logo" routerLink="/index" *ngIf="navClass === 'nav-light'">
        <img src="assets/images/logo-dark.png" class="l-dark" height="24" alt="">
        <img src="assets/images/logo-light.png" class="l-light" height="24" alt="">
      </a>
    </div>

    <span *ngIf="!buttonList && !isdeveloper && !shopPages">
      <div class="buy-button">
        <!-- <ng-template [ngIf]="navClass === 'nav-light'">
          <a href="javascript: void(0);" class="btn btn-primary login-btn-primary">Login</a>
          <a href="javascript: void(0);" class="btn btn-light login-btn-light">Login</a>
        </ng-template>
        <ng-template [ngIf]="navClass !== 'nav-light'">
          <a href="javascript: void(0);" class="btn btn-primary">Login</a> -->
        <!-- </ng-template> -->
      </div>
    </span>
    <!--end login button-->

    <div class="menu-extras">
      <div class="menu-item">
        <!-- Mobile menu toggle-->
        <a class="navbar-toggle" id="isToggle" (click)="toggleMenu()" [ngClass]="{'open': isCondensed === true}">
          <div class="lines">
            <span></span>
            <span></span>
            <span></span>
          </div>
        </a>
        <!-- End mobile menu toggle-->
      </div>
    </div>

    <div id="navigation">
      <!-- Navigation Menu-->
      <ul class="navigation-menu" [class]="navClass">
        <li><a href="#" class="nav-link-ref">Home</a></li>
        <li class="has-submenu">
          <a href="javascript:void(0)" (click)="onMenuClick($event)">Services</a><span class="menu-arrow"></span>
          <ul class="submenu">
            <li>
              <ul>
                <li><a class="nav-link js-scroll-trigger" routerLink="/page-express-earn">Earn</a></li>
                <li><a class="nav-link js-scroll-trigger" routerLink="/page-express-send">Send</a></li>
                <li><a class="nav-link js-scroll-trigger" routerLink="/page-express-receive">Receive</a></li>
                <li><a class="nav-link js-scroll-trigger" routerLink="/page-express-pay">Pay</a></li>
                <li><a class="nav-link js-scroll-trigger" routerLink="/page-express-order">Order</a></li>
                <li><a class="nav-link js-scroll-trigger" routerLink="/page-express-buy">Buy</a></li>
              </ul>
            </li>
          </ul>
        </li>


        <!-- <li class="has-submenu"> -->
        <li>
          <a href="#about">About Us</a><span class="menu-arrow"></span>
        </li>
        <li>
          <a href="#career">Careers</a><span class="menu-arrow"></span>
        </li>
        <li>
          <a href="#contacts">Contact Us</a><span class="menu-arrow"></span>
        </li>
        <li>
          <a href="merchant-application">Be a Merchant</a><span class="menu-arrow"></span>
        </li>
        <li>
          <a href="rider-application">Be a Rider</a><span class="menu-arrow"></span>
        </li>
        
        <!-- <li class="has-submenu">
          <a href="javascript:void(0)" (click)="onMenuClick($event)">Docs</a><span class="menu-arrow"></span>
          <ul class="submenu">
            <li><a class="nav-link-ref" routerLink="/documentation">Documentation</a></li>
            <li><a class="nav-link-ref" routerLink="/changelog">Changelog</a></li>
            <li><a class="nav-link-ref" routerLink="/components">Components</a></li>
            <li><a class="nav-link-ref" routerLink="/widget">Widget</a></li>
          </ul>
        </li> -->
      </ul>
      <!--end navigation menu-->
      <div class="buy-menu-btn d-none">
        <a href="javascript: void(0);" target="_blank" class="btn btn-primary">Login</a>
      </div>
      <!--end login button-->
      <!--end login button-->
    </div>
    <!--end navigation-->
  </div>
  <!--end container-->
</header>
<!--end header-->
<!-- Navbar End -->