<app-header></app-header>

<section id="" class="pt-5 mt-5">
    <div class="container">
      <!--end row-->
      <div class="row align-items-center">
        
        <!--end col-->
  
        <div class="col-md-8 col-12">
          <div class="section-title ms-lg-5">
            <h4 class="title mb-4">EXPRESS SEND</h4>
              <br>
            <p class="text-muted para-desc mb-0 mx-auto lh-lg int">Sending money can be tough when made through remittance centers. With our Express Send feature available on ourmyJuan Super App, you can experience a faster, newer and safer sending of funds. This service accentuates the funding, sending, and gifting for other myJuan Super App users and even for other E-wallets and Online Bank Applications.</p>
  
          </div>
        </div>
        <!--end col-->
        <div class="col-lg-4 col-md-4 col-12 mt-4 pt-2 text-center text-md-end">
          <img src="assets/images/app/send.png" class="img-fluid mx-auto d-block" alt="">
        </div>
      </div>
      <!--end row-->
    </div>
    <!--end container-->
  
  
    <!--end container-->
  </section>
  
  <section class="pt-5">
      <div class="container">
        <div class="row">
    
          <div class="col">
            <div class="section-title" id="">
              <h4>FAQs</h4>
            </div>
            <div class="faq-content mt-4 pt-2 pb-5">
              <ngb-accordion #acc="ngbAccordion" activeIds="ngb-panel-0" closeOthers="true">
                <ngb-panel id="ngb-panel-0" cardClass="border-0 rounded shadow mb-2">
                  <ng-template ngbPanelTitle>
                    <div class="border-0 bg-light" id="headingone">
                      <h6 class="title mb-0 text-dark">What is Express Send?</h6>
                    </div>
                  </ng-template>
                  <ng-template ngbPanelContent>
                    <p class="text-muted mb-0 faq-ans">Express Send is a function on your myJuan Super App that allows you to send funds to other users or accounts.</p>
                  </ng-template>
                </ngb-panel>
                <ngb-panel cardClass="border-0 rounded shadow mb-2">
                  <ng-template ngbPanelTitle>
                    <div class="border-0 bg-light" id="headingtwo">
                      <h6 class="title mb-0 text-dark">What are the ways you can send funds using your Vipco wallet?</h6>
                    </div>
                  </ng-template>
                  <ng-template ngbPanelContent>
                    <p class="text-muted mb-0 faq-ans">As a user, you can transmit your funds from your Vipco wallet to other myJuan user's wallet or other bank or e-wallet accounts.</p>
                  </ng-template>
                </ngb-panel>
                <ngb-panel cardClass="border-0 rounded shadow mb-2">
                  <ng-template ngbPanelTitle>
                    <div class="border-0 bg-light" id="headingthree">
                      <h6 class="title mb-0 text-dark">How do I send funds to other myJuan users?</h6>
                    </div>
                  </ng-template>
                  <ng-template ngbPanelContent>
                    <ol class="text-muted mb-0 faq-ans" type="a">
                        <li>Open Express Send</li>
                        <li>Type in the mobile number of the receiving person</li>
                        <li>Type the amount that you want to send</li>
                        <li>Review the receiving person's name and amount before you submit.</li>
                        <li>Wait for the notification to know if transaction is successful</li>
                    </ol>
                  </ng-template>
                </ngb-panel>
                <ngb-panel cardClass="border-0 rounded shadow mb-2">
                    <ng-template ngbPanelTitle>
                      <div class="border-0 bg-light" id="headingthree">
                        <h6 class="title mb-0 text-dark">How do I send funds using QR code?</h6>
                      </div>
                    </ng-template>
                    <ng-template ngbPanelContent>
                        <ol class="text-muted mb-0 faq-ans" type="a">
                            <li>Click the QR icon on the dashboard</li>
                            <li>Select Scan QR</li>
                            <li>Scan the QRPH provided</li>
                            <li>Type the amount that you want to send</li>
                            <li>Review the receiving person's name and amount before you submit</li>
                            <li>Wait for the notification to know if transaction is successful</li>
                        </ol>
                    </ng-template>
                  </ngb-panel>
              </ngb-accordion>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end container-->
      <div class="container pb-5">
          <ul class="list-unstyled text-muted">
              <i-feather name="arrow-left-circle" class="fea icon-sm text-primary me-2"></i-feather>
              <a href="#">Go Back to Homepage</a>
          </ul>
  
      </div>
    </section>
  
   
  
    <app-footer></app-footer> 