<app-header></app-header>
<section class="pt-5 pb-5 mb-5 d-table w-100" id="hero">
  <div class="box1"></div>
  <div class="container">
    <div class="row mt-5 align-items-center justify-content-center">
      <div class="col-lg-6 col-md-7">
        <div class="title-heading" data-aos="fade-right" data-aos-duration="1000">
          <h1 class="heading mb-3"><span class="text-primary">myJuan Rider</span></h1>
          <p class="text-muted int h6 mt-4" data-aos="fade-up-right">There's always a hungry customer to satisfy. Every day, myJuan Riders bring smiles to millions of customers. So sign up now, get your gears, and hit the road.</p>
          <div class="d-flex mt-3 text-center" data-aos="fade-up" data-aos-duration="1500">
            <!-- <a href="page-maintenance" class="btn btn-primary mt-2 me-2"><i class="uil uil-apple"></i> App Store</a> -->
            <a href="https://play.google.com/store/apps/details?id=com.vipco_mega_multi_wallet_rider_app&fbclid=IwAR0d9qXAlrIyvPt_19Tf-ZKfMb35fVbXlrYsI89Kwfe4xwlFQ3FRvPY5fVA" class="btn btn-outline-primary mt-2 d-flex align-items-center gap-1 hov"><i class="uil uil-google-play"></i> Play Store</a>
          </div>
        </div>
      </div>
      <!--end col-->
      <div class="col-lg-4 col-md-5 mt-4 pt-2 mt-sm-0 pt-sm-0">
        <div class="text-md-end text-center ms-lg-4 mt-3" data-aos="fade-left" data-aos-duration="1000">
          <img src="../../../../assets/myjuan/Rider.png" alt="" width="500px">
        </div>
      </div>
    </div>
    
    <div class="row icon-boxes justify-content-center align-items-center">
      <div class="col-xs-6 col-sm-4 align-items-center mb-5 mb-lg-0" data-aos="zoom-in" data-aos-delay="200">
        <div class="icon-box">
          <div class="icon"><i class="fa-solid fa-utensils"></i></div>
          <h4 class="title"><a href="">Food</a></h4>
         
        </div>
      </div>

      <div class="col-xs-6 col-sm-4 align-items-center mb-5 mb-lg-0" data-aos="zoom-in" data-aos-delay="300">
        <div class="icon-box">
          <div class="icon"><i class="fa-solid fa-motorcycle"></i></div>
          <h4 class="title"><a href="">Delivery</a></h4>
          
        </div>
      </div>

      <div class="col-xs-6 col-sm-4 align-items-center mb-5 mb-lg-0" data-aos="zoom-in" data-aos-delay="400">
        <div class="icon-box">
          <div class="icon"><i class="fa-solid fa-gears"></i></div>
          <h4 class="title"><a href="">Services</a></h4>
          
        </div>
      </div>
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<br><br>
<section class="pt-5 pb-5 mb-5 d-table w-100">
  <div class="container">
    <div class="blob1"></div>
    <div class="row mt-10 align-items-center justify-content-around">
      <div class="col-lg-5 col-md-5 mt-4 pt-2 mt-sm-0 pt-sm-0 justify-content-start">
        <div class="text-md-end text-center" data-aos="fade-right" data-aos-duration="1000">
          <!--<img src="assets/images/app/vip88.svg" class="img-fluid app" alt="" width="500">-->
          <img src="assets/images/rider_back.png" class="img-fluid app rounded" alt="" width="500">
        </div>
      </div>
      <div class="col-lg-6 col-md-7 ms-lg-2">
        <div class="title-heading" data-aos="fade-right" data-aos-duration="1000">
          <h1 class="mb-3 text-primary text-left">Who is a myJuan Rider?</h1>
          <p class="text-muted pg h6 int" data-aos="fade-up-right">myJuan Riders are in charge of providing excellent service to myJuan customers by delivering food and/or items in a safe and timely manner. He manages multiple orders, delivery locations, and customers at the same time. He ensures that orders are delivered correctly and in good condition. Finally, he represents the company with excellence and professionalism by driving safely, exercising good judgment, and treating customers with respect.</p>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<br><br><br><br><br><br>
<section class="pt-5 pb-5 mb-5 d-table w-100">
  <div class="container">
    <div class="blob1"></div>
    <div class="row mt-10 align-items-center justify-content-around">
      <div class="col-lg-6 col-md-7 ms-lg-2">
        <div class="title-heading" data-aos="fade-right" data-aos-duration="1000">
          <h1 class="mb-3 text-primary text-left">Why become a Partner Rider?</h1>
          <p class="text-muted pg h6 int" data-aos="fade-up-right">We prioritize our riders by ensuring they receive the highest pay and all the assistance they require to efficiently meet their community delivery needs. More deliveries mean more earnings!</p>
        </div>
      </div>
      <div class="col-lg-5 col-md-5 mt-4 pt-2 mt-sm-0 pt-sm-0 justify-content-start">
        <div class="text-md-end text-center" data-aos="fade-right" data-aos-duration="1000">
          <!--<img src="assets/images/app/vip88.svg" class="img-fluid app" alt="" width="500">-->
          <img src="assets/images/app/rider-ani.gif" class="img-fluid app rounded" alt="" width="500">
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<br><br><br><br>
<section class="section">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 text-center">
        <div class="title-heading mb-4 pb-2">
          <h4 class="title mb-4 text-primary h1" data-aos="fade-left" data-aos-duration="1000">Accepted Vehicles</h4>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->

    <div class="row justify-content-center">
      <div class="col-lg-3 col-md-4 mt-4 pt-2" data-aos="fade-left" data-aos-duration="1000">
        <div
          class="card features fea-primary rounded p-4 bg-light text-center position-relative overflow-hidden border-0">
          <div class="card-body p-0 content">
            <h5 class="text-muted">Motorcycle</h5>
          </div>
          <img class="img5" src="assets/images/app/rider.svg" alt="">
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-3 col-md-4 mt-4 pt-2" data-aos="fade-left" data-aos-duration="1000">
        <div
          class="card features fea-primary rounded p-4 bg-light text-center position-relative overflow-hidden border-0">
          <div class="card-body p-0 content">
            <h5 class="text-muted">Bicycle (Coming Soon)</h5>
          </div>
          <img class="img6" src="assets/images/app/bike.svg" alt="">
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>

<section class="pt-5 pb-5 mb-5 d-table w-100">
  <div class="container position-relative">
    <div class="blob3"></div>
    <div class="row mt-5 align-items-center justify-content-left">
      <div class="col-lg-8 col-md-7">
        <div class="title-heading ms-lg-5" data-aos="fade-right" data-aos-duration="1000">
          <h1 class="title mb-4 text-primary"><span class="text-primary">Requirements</span></h1>
          <ul class="list-unstyled text-muted">
            <li class="mb-0 h5"><span class="text-primary h5 me-2"><i
                  class="uil uil-check-circle align-middle"></i></span>Between the ages of 18 and 65</li><br>
            <li class="mb-0 h5"><span class="text-primary h5 me-2"><i
                  class="uil uil-check-circle align-middle"></i></span>Valid Vehicle Official Receipt (OR)</li><br>
            <li class="mb-0 h5"><span class="text-primary h5 me-2"><i
                  class="uil uil-check-circle align-middle"></i></span>Valid Vehicle Certificate of Registration (CR)</li><br>
                  <li class="mb-0 h5"><span class="text-primary h5 me-2"><i
                    class="uil uil-check-circle align-middle"></i></span>Valid Driver’s License (Non-Professional & Professional only)</li><br>
              <li class="mb-0 h5"><span class="text-primary h5 me-2"><i
                    class="uil uil-check-circle align-middle"></i></span>Valid NBI Clearance</li><br>
              <li class="mb-0 h5"><span class="text-primary h5 me-2"><i
                    class="uil uil-check-circle align-middle"></i></span>Valid Drug Test Clearance (Optional)</li><br>
              <li class="mb-0 h5"><span class="text-primary h5 me-2"><i
                    class="uil uil-check-circle align-middle"></i></span>Valid Vaccination Card (Fully Vaccinated)</li><br>   
              <li class="mb-0 h5"><span class="text-primary h5 me-2"><i
                    class="uil uil-check-circle align-middle"></i></span>Vehicle Photo (Front, Side, Back)</li><br>   
          </ul>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-4 col-md-5 mt-4 pt-2 mt-sm-0 pt-sm-0">
        <div class="text-md-end text-center ms-lg-4" data-aos="fade-left" data-aos-duration="1000">
          <img src="assets/images/app/req.svg" class="img-fluid app" alt="" width="500">
        </div>
      </div>
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>

<section class="pt-5 pb-5 mb-5 d-table w-100">
  <div class="container position-relative">
    <div class="blob4"></div>
    <div class="row mt-5 align-items-center gap-5">
      <div class="col-lg-5 col-md-5 mt-4 pt-2 mt-sm-0 pt-sm-0">
        <div class="text-md-end text-center ms-lg-2" data-aos="fade-left" data-aos-duration="1000">
          <img src="assets/images/app/rider.svg" class="img0" alt="" width="700">
        </div>
      </div>
      <div class="col-lg-5 col-md-7 ms-lg-5">
        <div class="title-heading" data-aos="fade-right" data-aos-duration="1000">
          <h1 class="title mb-4 text-primary"><span class="text-primary h2">Rider Merch / Gears</span></h1>
          <ul class="list-unstyled text-muted ms-lg-3">
            <li class="mb-0 h5"><span class="text-primary h5 me-2">
              <i class="fa-solid fa-vest-patches align-middle"></i></span>2 Uniforms</li><br>
            <li class="mb-0 h5"><span class="text-primary h5 me-2">
              <i class="fa-regular fa-address-card align-middle"></i></span>myJuan ID</li><br>
            <li class="mb-0 h5"><span class="text-primary h5 me-2">
              <i class="fa-solid fa-helmet-un"></i></span>Helmet</li><br>
            <li class="mb-0 h5"><span class="text-primary h5 me-2">
              <i class="fa-regular fa-note-sticky"></i></span>Decals</li><br>
            <li class="mb-0 h5"><span class="text-primary h5 me-2">
              <i class="fa-solid fa-briefcase"></i></span>Delivery Bag</li><br>
          </ul>
        </div>
      </div>
      <!--end col-->


    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<br><br><br>
<section class="pt-5 pb-5 mb-5 d-table w-100">
  <div class="container">
    <div class="row mt-5 align-items-center justify-content-center">
      <div class="col-lg-12 col-md-7 ms-lg-4">
        <div class="title-heading" data-aos="fade-right" data-aos-duration="1000">
          <h1 class="title mb-4 text-primary text-center"><span class="text-primary">Benefits / Advantages</span></h1>
          <div class="row justify-content-center">

            <div class=".col-xs-6 .col-md-10 col-lg-4 mt-4 pt-2" data-aos="fade-left" data-aos-duration="1000">
              <div
                class="card rounded p-4 bg-light text-center position-relative overflow-hidden border-0">
                <div class="card-body p-0 content">
                  <h5 class="text-primary">Receive more Earnings</h5>
                  <h5 class="text-muted h6">Earn 95% of the delivery fee. The more deliveries you make, the more money you earn.</h5><br><br>
                </div>
              </div>
            </div>

            <div class=".col-xs-6 .col-md-10 col-lg-4 mt-4 pt-2" data-aos="fade-left" data-aos-duration="1000">
              <div
                class="card rounded p-4 bg-light text-center position-relative overflow-hidden border-0">
                <div class="card-body p-0 content">
                  <h5 class="text-primary">Flexible Delivery Hours</h5>
                  <h5 class="text-muted h6">You are your own boss; you decide when, where, and how frequently you will deliver.</h5><br><br>
                </div>
              </div>
            </div>

            <div class=".col-xs-6 .col-md-10 col-lg-4 mt-4 pt-2" data-aos="fade-left" data-aos-duration="1000">
              <div
                class="card rounded p-4 bg-light text-center position-relative overflow-hidden border-0">
                <div class="card-body p-0 content">
                  <h5 class="text-primary">All-in-one tool</h5>
                  <h5 class="text-muted h6">Your myJuan Rider app contains all the necessary tools for your deliveries; from receiving deliveries, and in-app navigation, to tracking and cashing out your earnings.</h5>
                </div>
              </div>
            </div>

            <div class=".col-xs-6 .col-md-10 col-lg-4 mt-4 pt-2" data-aos="fade-left" data-aos-duration="1000">
              <div
                class="card rounded p-4 bg-light text-center position-relative overflow-hidden border-0">
                <div class="card-body p-0 content">
                  <h5 class="text-primary">Built-in wallet</h5>
                  <h5 class="text-muted h6">myJuan Rider app has a VIPCo wallet that will let you top up, send funds, and cash out earnings.</h5>
                </div>
              </div>
            </div>

            <div class=".col-xs-6 .col-md-10 col-lg-4 mt-4 pt-2" data-aos="fade-left" data-aos-duration="1000">
              <div
                class="card rounded p-4 bg-light text-center position-relative overflow-hidden border-0">
                <div class="card-body p-0 content">
                  <h5 class="text-primary">Income Statistics</h5>
                  <h5 class="text-muted h6">Track your completed bookings and earnings daily, weekly, and monthly.</h5><br>
                </div>
              </div>
            </div>

            <div class=".col-xs-6 .col-md-10 col-lg-4 mt-4 pt-2" data-aos="fade-left" data-aos-duration="1000">
              <div
                class="card rounded p-4 bg-light text-center position-relative overflow-hidden border-0">
                <div class="card-body p-0 content">
                  <h5 class="text-primary">Free Insurance coverage</h5>
                  <h5 class="text-muted h6">Personal Accident Insurance will be provided at no cost while making deliveries.</h5>
                </div>
              </div>
            </div>

            <div class=".col-xs-6 .col-md-10 col-lg-4 mt-4 pt-2" data-aos="fade-left" data-aos-duration="1000">
              <div
                class="card rounded p-4 bg-light text-center position-relative overflow-hidden border-0">
                <div class="card-body p-0 content">
                  <h5 class="text-primary">Multiple bookings</h5>
                  <h5 class="text-muted h6">Earn more delivery fees with just one pick-up location and multiple drop-off locations.</h5>
                </div>
              </div>
            </div>
            <!--end col-->
      
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>

<section class="section">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 text-center">
        <div class="title-heading mb-4 pb-2">
          <h4 class="title mb-4 text-primary h1" data-aos="fade-left" data-aos-duration="1000">How will you earn?</h4>
          <p class="text-muted para-desc mb-0 mx-auto h5" data-aos="fade-left" data-aos-duration="1000">We offer not just our service but also our hearts in everything we do. Start earning more with<span
              class="text-primary fw-bold"> myJuan</span> and let your business grow stronger and better.</p>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->

    <div class="row justify-content-center">
      <div class="col-xs-6 col-md-4 mt-4 pt-2" data-aos="fade-left" data-aos-duration="1000">
        <div
          class="card features fea-primary rounded p-4 bg-light text-center position-relative overflow-hidden border-0">
          <span class="small-icon text-center">
            <i class="fa-regular fa-calendar-check"></i>
          </span>
          <div class="card-body p-0 content">
            <h5 class="text-muted">Step 1:</h5>
            <h5 class="textarea-large text-primary">Accept Delivery Booking</h5>
          </div>
          <span class="big-icon text-center">
            <i class="fa-regular fa-calendar-check"></i>
          </span>
        </div>
      </div>
      <!--end col-->

      <div class="col-xs-6 col-md-4 mt-4 pt-2" data-aos="fade-left" data-aos-duration="1000">
        <div
          class="card features fea-primary rounded p-4 bg-light text-center position-relative overflow-hidden border-0">
          <span class="small-icon text-center">
            <i class="fa-solid fa-hand-holding-heart"></i>
          </span>
          <div class="card-body p-0 content">
            <h5 class="text-muted">Step 2:</h5>
            <h5 class="textarea-large text-primary">Collect the food</h5>
          </div>
          <span class="big-icon text-center">
            <i class="fa-solid fa-hand-holding-heart"></i>
          </span>
        </div>
      </div>
      <!--end col-->

      <div class="col-xs-6 col-md-4 mt-4 pt-2" data-aos="fade-right" data-aos-duration="1000">
        <div
          class="card features fea-primary rounded p-4 bg-light text-center position-relative overflow-hidden border-0">
          <span class="small-icon text-center">
            <i class="fa-solid fa-truck-ramp-box"></i>
          </span>
          <div class="card-body p-0 content">
            <h5 class="text-muted">Step 3:</h5>
            <h5 class="textarea-large text-primary">Deliver the food</h5>
          </div>
          <span class="big-icon text-center">
            <i class="fa-solid fa-truck-ramp-box"></i>
          </span>
        </div>
      </div>
      
      <!--end col-->
      <div class="col-xs-6 col-md-4 mt-4 pt-2" data-aos="fade-right" data-aos-duration="1000">
        <div
          class="card features fea-primary rounded p-4 bg-light text-center position-relative overflow-hidden border-0">
          <span class="small-icon text-center">
            <i class="fa-solid fa-hand-holding-dollar"></i>
          </span>
          <div class="card-body p-0 content">
            <h5 class="text-muted">Step 4:</h5>
            <h5 class="textarea-large text-primary">Collect the payment</h5>
          </div>
          <span class="big-icon text-center">
            <i class="fa-solid fa-hand-holding-dollar"></i>
          </span>
        </div>
      </div>

      <div class="col-xs-6 col-md-4 mt-4 pt-2" data-aos="fade-right" data-aos-duration="1000">
        <div
          class="card features fea-primary rounded p-4 bg-light text-center position-relative overflow-hidden border-0">
          <span class="small-icon text-center">
            <i class="fa-solid fa-circle-dollar-to-slot"></i>
          </span>
          <div class="card-body p-0 content">
            <h5 class="text-muted">Step 5:</h5>
            <h5 class="textarea-large text-primary">Earn 95% of the paid delivery fee</h5>
          </div>
          <span class="big-icon text-center">
            <i class="fa-solid fa-circle-dollar-to-slot"></i>
          </span>
        </div>
      </div>

    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>

<section class="section">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 text-center">
        <div class="title mb-1 pb-2">
          <h5 class="title mb-2 text-primary h1" data-aos="fade-left" data-aos-duration="1000">Partner Driver Application Process</h5>
          <p class="text-muted para-desc mb-0 mx-auto h6" data-aos="fade-up" data-aos-duration="1000">It's not difficult to partner up with myJuan Rider. All you need to do is enter some basic information and complete the Rider requirements and you're ready to go.</p>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
    <h4 class="mb-0 text-primary h5 mt-2 text-center" data-aos="fade-up" data-aos-duration="1000">Steps to apply as a Partner Rider:</h4>
    <div class="row justify-content-center">
      <div class="col-lg-4 col-md-4 mt-4 pt-2" data-aos="fade-right" data-aos-duration="1000">
        <div
          class="card rounded p-4  text-justify position-relative overflow-hidden border-0 align-items-center">
            <img class="img9" src="assets/images/app/down.svg" alt="">
          <div class="card-body p-0 content text-center">
            <h5 class="text-muted text-center">Step 1:</h5>
            <h5 class="textarea-large">Download myJuan Rider from Google Play Store.</h5>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-4 col-md-4 mt-4 pt-2" data-aos="fade-down" data-aos-duration="1000">
        <div
          class="card rounded p-4  text-justify position-relative overflow-hidden border-0 align-items-center">
          <img class="img9" src="assets/images/app/regist.svg" alt="">
          <div class="card-body p-0 content text-center">
            <h5 class="text-muted text-center">Step 2:</h5>
            <h5 class="textarea-large">Complete the registration process.</h5>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-4 col-md-4 mt-4 pt-2" data-aos="fade-left" data-aos-duration="1000">
        <div
          class="card rounded p-4  text-justify position-relative overflow-hidden border-0 align-items-center">
          <img class="img9" src="assets/images/app/activate.svg" alt="">
          <div class="card-body p-0 content text-center">
            <h5 class="text-muted text-center">Step 3:</h5>
            <h5 class="textarea-large">To activate the account, scan/upload/enter the reference code generated from JuanPay or myJuan Operators.</h5>
          </div>
        </div>
      </div>
      
      <!--end col-->
      <div class="col-lg-4 col-md-4 mt-4 pt-2" data-aos="fade-right" data-aos-duration="1000">
        <div
          class="card rounded p-4  text-justify position-relative overflow-hidden border-0 align-items-center">
          <img class="img9" src="assets/images/app/complete.svg" alt="">
          <div class="card-body p-0 content text-center">
            <h5 class="text-muted text-center">Step 4:</h5>
            <h5 class="textarea-large">Complete all Rider requirements within the app and allow 48 hours for your application to be approved.</h5>
          </div>
        </div>
      </div>

      <div class="col-lg-4 col-md-4 mt-4 pt-2" data-aos="fade-up" data-aos-duration="1000">
        <div
          class="card rounded p-4  text-justify position-relative overflow-hidden border-0 align-items-center">
          <img class="img9" src="assets/images/app/clock.svg" alt="">
          <div class="card-body p-0 content text-center">
            <h5 class="text-muted text-center">Step 5:</h5>
            <h5 class="textarea-large">Top up your myJuan Rider wallet with at least Php 500.00 at any JuanPay location.</h5>
          </div>
        </div>
      </div>

      <div class="col-lg-4 col-md-4 mt-4 pt-2" data-aos="fade-left" data-aos-duration="1000">
        <div
          class="card rounded p-4  text-justify position-relative overflow-hidden border-0 align-items-center">
          <img class="img9" src="assets/images/app/finish.svg" alt="">
          <div class="card-body p-0 content text-center">
            <h5 class="text-muted text-center">Step 6:</h5>
            <h5 class="textarea-large">Pay and collect your myJuan uniform, helmet, and delivery bag at your linked outlet or coordinate with our Head Office.</h5>
          </div>

        </div>
      </div>

    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>

<section class="pt-5 pb-5 mb-5 d-table w-100" data-aos="fade-up" data-aos-duration="1000">
  <div class="container">
  <div class="row d-flex justify-content-center">
    <div class="col-md-10 col-xl-8 text-center">
      <h3 class="mb-1 text-primary"data-aos="fade-left" data-aos-duration="1000">Testimonials</h3>
      <p class="mb-1 pb-1 mb-md-5 pb-md-0 text-muted"data-aos="fade-left" data-aos-duration="1000">
        Here are some of their thoughts on our myJuan Rider app.
      </p>
    </div>
  </div>

  <div class="row text-center" data-aos="fade-up" data-aos-duration="1000">
    <div class="col-md-4 mb-5 mb-md-0">
      <div class="d-flex justify-content-center mb-4">
        <img src="\assets\images\app\lyndon.png"
          class="rounded-circle shadow-1-strong shadow-lg" width="150" height="150" />
      </div>
      <h5 class="mb-3">Lyndon Perez</h5>
      <h6 class="text-primary mb-3">Rider</h6>
      <p class="px-xl-3">
        <i class="fas fa-quote-left pe-2"></i>Accept all incoming orders, regardless of the distance between the pick-up and drop-off points. Check that all items are in good condition before delivering them to the customer to ensure their satisfaction.
        <i class="fas fa-quote-right pe-2"></i>
      </p>
      <ul class="list-unstyled d-flex justify-content-center mb-0">
        <li>
          <i class="fas fa-star fa-sm text-warning"></i>
        </li>
        <li>
          <i class="fas fa-star fa-sm text-warning"></i>
        </li>
        <li>
          <i class="fas fa-star fa-sm text-warning"></i>
        </li>
        <li>
          <i class="fas fa-star fa-sm text-warning"></i>
        </li>
        <li>
          <i class="fas fa-star fa-sm text-warning"></i>
        </li>
      </ul>
    </div>
    <div class="col-md-4 mb-0">
      <div class="d-flex justify-content-center mb-4">
        <img src="\assets\images\app\pando.png"
          class="rounded-circle shadow-1-strong shadow-lg" width="150" height="150" />
      </div>
      <h5 class="mb-3">Fernando Centeno</h5>
      <h6 class="text-primary mb-3">Rider</h6>
      <p class="px-xl-3">
        <i class="fas fa-quote-left pe-2"></i>I appreciate myJuan Rider's flexible hours, which allow me to fulfill my other obligations. I have enough time for both work and family. I give it two thumbs up!
        <i class="fas fa-quote-right pe-2"></i>
      </p>
      <ul class="list-unstyled d-flex justify-content-center mb-0">
        <li>
          <i class="fas fa-star fa-sm text-warning"></i>
        </li>
        <li>
          <i class="fas fa-star fa-sm text-warning"></i>
        </li>
        <li>
          <i class="fas fa-star fa-sm text-warning"></i>
        </li>
        <li>
          <i class="fas fa-star fa-sm text-warning"></i>
        </li>
        <li>
          <i class="fas fa-star fa-sm text-warning"></i>
        </li>
      </ul>
    </div>
    <div class="col-md-4 mb-5 mb-md-0">
      <div class="d-flex justify-content-center mb-4">
        <img src="\assets\images\app\kate.png"
          class="rounded-circle shadow-1-strong shadow-lg" width="150" height="150" />
      </div>
      <h5 class="mb-3">Kate Delan</h5>
      <h6 class="text-primary mb-3">Rider</h6>
      <p class="px-xl-3">
        <i class="fas fa-quote-left pe-2"></i>As a myJuan Rider, my earnings rate is higher than in my previous delivery jobs. myJuan is providing us with additional income, more than enough to support a full-time day or night job. I recommend this to any driver looking for a steady income.
        <i class="fas fa-quote-right pe-2"></i>
      </p>
      <ul class="list-unstyled d-flex justify-content-center mb-0">
        <li>
          <i class="fas fa-star fa-sm text-warning"></i>
        </li>
        <li>
          <i class="fas fa-star fa-sm text-warning"></i>
        </li>
        <li>
          <i class="fas fa-star fa-sm text-warning"></i>
        </li>
        <li>
          <i class="fas fa-star fa-sm text-warning"></i>
        </li>
        <li>
          <i class="fas fa-star fa-sm text-warning"></i>
        </li>
      </ul>
    </div>
  </div>
</div>
</section>
<section class="pt-5 pb-5 mb-5 d-table w-100">
  <div class="container">
  <div class="row d-flex justify-content-center">
    <div class="row-md-10 col-xl-8 text-center">
      <h3 class="mb-4" data-aos="fade-left" data-aos-duration="1000">Join our <span class="text-primary"> myJuan</span> Community <br> as a Partner Rider now!</h3>
    </div>
    <div class="d-flex mt-4 gap-2 justify-content-center text-center" data-aos="fade-up" data-aos-duration="1500">
      <!-- <a href="page-maintenance" class="btn btn-primary mt-2 me-2"><i class="uil uil-apple"></i> App Store</a> -->
      <a href="https://play.google.com/store/apps/details?id=com.vipco_mega_multi_wallet_rider_app&fbclid=IwAR0d9qXAlrIyvPt_19Tf-ZKfMb35fVbXlrYsI89Kwfe4xwlFQ3FRvPY5fVA" class="btn btn-outline-primary mt-2 d-flex align-items-center gap-1 hov"><i class="uil uil-google-play"></i> Play Store</a>
    </div>
  </div>

</div>
</section>
<div class="position-relative">
  <div class="shape overflow-hidden text-footer">
    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
    </svg>
  </div>
</div>
<app-footer></app-footer> 