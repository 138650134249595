<app-header></app-header>

<section id="" class="pt-5 mt-5">
    <div class="container">
      <!--end row-->
      <div class="row align-items-center">
        
        <!--end col-->
  
        <div class="col-md-8 col-12">
          <div class="section-title ms-lg-5">
            <h4 class="title mb-4">EXPRESS ORDER</h4>
              <br>
            
            <p class="text-muted para-desc mb-0 mx-auto lh-lg int">Ordering through online applications has been with us for how many years and has been a great help. At this moment, our myJuan Super App introduces an ordering system that has a wide variety of services. This feature can also help you earn rebates that can be used for making transactions. Listed below are the four main services that can be used under Express Delivery. 
            </p>
            <br>
                <ul class="text-muted mb-0 para-desc mx-auto lh-lg" style="list-style: none;">
                    <li>
                        <span class="text-primary fw-bold">PARCEL DELIVERY</span> -  Users may send packages that can be delivered within the day at a specific area radius (Delivery time may vary depending on the item/s the user demands to be delivered). 
                    </li>
                    <li>
                        <span class="text-primary fw-bold">PABILI DELIVERY </span> - In this service, users may demand their booked riders specific items that can be bought from a specific store.
                    </li>
                </ul>

  
          </div>
        </div>
        <!--end col-->
        <div class="col-lg-4 col-md-4 col-12 mt-4 pt-2 text-center text-md-end">
          <img src="assets/images/app/order.png" class="img-fluid mx-auto d-block" alt="">
        </div>
      </div>
      <!--end row-->
    </div>
    <!--end container-->
  
  
    <!--end container-->
  </section>
  <br><br>
  <section>
      <div class="container">
        <div class="row">
    
          <div class="col">
            <div class="section-title" id="">
              <h4>FAQs</h4>
            </div>
            <div class="faq-content mt-4 pt-2 pb-5">
              <ngb-accordion #acc="ngbAccordion" activeIds="ngb-panel-0" closeOthers="true">
                <ngb-panel id="ngb-panel-0" cardClass="border-0 rounded shadow mb-2">
                  <ng-template ngbPanelTitle>
                    <div class="border-0 bg-light" id="headingone">
                      <h6 class="title mb-0 text-dark">What is Express Order?</h6>
                    </div>
                  </ng-template>
                  <ng-template ngbPanelContent>
                    <p class="text-muted mb-0 faq-ans">Express Delivery  is a premium delivery service that aims to make it easy for residents to arrange deliveries with a few simple clicks. Send documents, goods, packages, and gifts to business partners or loved ones while tracking their real-time delivery.</p>
                  </ng-template>
                </ngb-panel>
                <ngb-panel cardClass="border-0 rounded shadow mb-2">
                  <ng-template ngbPanelTitle>
                    <div class="border-0 bg-light" id="headingtwo">
                      <h6 class="title mb-0 text-dark">What is Same Day Delivery?</h6>
                    </div>
                  </ng-template>
                  <ng-template ngbPanelContent>
                    <p class="text-muted mb-0 faq-ans">It enables the user to immediately schedule a delivery.</p>
                  </ng-template>
                </ngb-panel>

                <ngb-panel cardClass="border-0 rounded shadow mb-2">
                    <ng-template ngbPanelTitle>
                      <div class="border-0 bg-light" id="headingthree">
                        <h6 class="title mb-0 text-dark">What is Pabili Service?</h6>
                      </div>
                    </ng-template>
                    <ng-template ngbPanelContent>
                      <p class="text-muted mb-0 faq-ans">It allows the user to book a ride to buy things from non-partner merchants for delivery.</p>
                    </ng-template>
                  </ngb-panel>
     
                  <ngb-panel cardClass="border-0 rounded shadow mb-2">
                    <ng-template ngbPanelTitle>
                      <div class="border-0 bg-light" id="headingthree">
                        <h6 class="title mb-0 text-dark">How can I book with Express Order?</h6>
                      </div>
                    </ng-template>
                    <ng-template ngbPanelContent>
                      <ol class="text-muted mb-0 faq-ans">
                        <li>Select the Xpress or Pabili in your myJuan Super App.</li>
                        <li>Enter your pick-up and drop-off locations.</li>
                        <li>Enter the recipient's name and phone number.</li>
                        <li>Confirm your booking.</li>
                        <li>
                          If a rider is assigned to you, you can call him or her at the phone number
                          indicated if you have any questions or need more instructions.
                        </li>
                        <li>
                          Share the ETA with the receiver of your package so that he or she may plan
                          ahead of time for arrival.
                        </li>
                      </ol>
                    </ng-template>
                    
                  </ngb-panel>
                  <ngb-panel cardClass="border-0 rounded shadow mb-2">
                    <ng-template ngbPanelTitle>
                      <div class="border-0 bg-light" id="headingthree">
                        <h6 class="title mb-0 text-dark">What are my payment options?</h6>
                      </div>
                    </ng-template>
                    <ng-template ngbPanelContent>
                      <p class="text-muted mb-0 faq-ans">Cash or using your Vipco Wallet</p>
                    </ng-template>
                  </ngb-panel>
              </ngb-accordion>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end container-->
      <div class="container pb-5">
          <ul class="list-unstyled text-muted">
              <i-feather name="arrow-left-circle" class="fea icon-sm text-primary me-2"></i-feather>
              <a href="#">Go Back to Homepage</a>
          </ul>
  
      </div>
    </section>
  
   
  
    <app-footer></app-footer> 