<img src="assets/images/app/cool-background2.svg" class="position-absolute container-fluid img002" alt="">
<app-header></app-header>
<section id="" class="pt-5 mt-5">
  <div class="container">
    <div class="btn-group btn-group-toggle container px-5" data-toggle="buttons">
      <label class="btn btn-secondary active" >
         <a href="/faqs-vips" routerLink="/faqs-vips">myJuan Super App FAQs</a>
      </label>
      <label class="btn btn-secondary">
          <a href="/faqs-vips-merchant" routerLink="/faqs-vips-merchant">myJuan Merchant App FAQs</a>
        </label>
      <label class="btn btn-secondary">
          <a href="/faqs-vips-rider" routerLink="/faqs-vips-rider">myJuan Rider App FAQs</a>
      </label>
    </div>
    <!--end row-->
    <div class="row align-items-center">
      
      <!--end col-->

      <div class="col-md-8 col-12">
        <div class="title ms-lg-5">
          <h4 class="title mb-4 text-primary h2">myJuan Super App</h4>
          <p class="text-muted mb-4">myJuan Super App is the only app you need to cover all your needs - from payments, deliveries, investments and even rewards!</p>
            <br>
        </div>
      </div>
      <!--end col-->
     <div class="col-lg-4 col-md-4 col-12 mt-4 pt-2 text-center text-md-end">
        <img src="../../../../assets/myjuan/About Us.png" height="600px" class="img-fluid mx-auto d-block" alt="">
      </div>
    </div>
    <!--end row-->
  </div>
  <!--end container-->


  <!--end container-->
</section><br><br><br>
<img src="assets/images/app/cool-background5.png" class="position-absolute container-fluid img001" alt="">
<img src="assets/images/app/cool-background5.png" class="position-absolute container-fluid img003" alt="">
<img src="assets/images/app/cool-background5.png" class="position-absolute container-fluid img004" alt="">
<section class="position-relative">
    <div class="container">
      <div class="row">
  
        <div class="col">
          <div class="section-title" id="">
            <h4>FAQs</h4>
          </div>
          <div class="faq-content mt-4 pt-2 pb-5">
            <ngb-accordion #acc="ngbAccordion" activeIds="ngb-panel-0" closeOthers="true">
              <ngb-panel id="ngb-panel-0" cardClass="border-0 rounded shadow-md mb-2">
                <ng-template ngbPanelTitle>
                  <div class="border-0 bg-light" id="headingone">
                    <h6 class="title mb-0 text-dark">How to download myJuan via Play Store?</h6>
                  </div>
                </ng-template>
                <ng-template ngbPanelContent>
                  <p class="text-muted mb-0 faq-ans">1. On your smartphone, open the Play Store app and search for “Vips”.</p><br>
                  <p class="text-muted mb-0 faq-ans">2. Tap download, once completed open the app, and continue with the registration.</p>
                </ng-template>
              </ngb-panel>
              <ngb-panel cardClass="border-0 rounded shadow-md mb-2">
                <ng-template ngbPanelTitle>
                  <div class="border-0 bg-light" id="headingtwo">
                    <h6 class="title mb-0 text-dark">How to download myJuan PH via App Store?</h6>
                  </div>
                </ng-template>
                <ng-template ngbPanelContent>
                  <p class="text-muted mb-0 faq-ans">1. On your smartphone, open the App Store and search for “MyJuan”.</p><br>
                  <p class="text-muted mb-0 faq-ans">2. Tap download, once completed open the app, and continue with the registration.</p>
                </ng-template>
              </ngb-panel>
              <ngb-panel cardClass="border-0 rounded shadow-md mb-2">
                <ng-template ngbPanelTitle>
                  <div class="border-0 bg-light" id="headingthree">
                    <h6 class="title mb-0 text-dark">How to register?</h6>
                  </div>
                </ng-template>
                <ng-template ngbPanelContent>
                    <p class="text-muted mb-0 faq-ans">1. Enter your preferred 11-digit mobile number</p><br>
                    <p class="text-muted mb-0 faq-ans">2. Verify your mobile number and enter your 6-digit OTP.</p><br>
                    <p class="text-muted mb-0 faq-ans">3. Fill out your Basic Information to verify your identity.</p><br>
                    <p class="text-muted mb-0 faq-ans">4. Set up your 4-digit MPIN code to protect your account.</p><br>
                    <p class="text-muted mb-0 faq-ans">5. Scan/Upload the QR CODE or enter the reference code of your preferred JuanPay outlet to link your account.</p>
                </ng-template>
              </ngb-panel>

              <ngb-panel cardClass="border-0 rounded shadow-md mb-2">
                <ng-template ngbPanelTitle>
                  <div class="border-0 bg-light" id="headingthree">
                    <h6 class="title mb-0 text-dark">How to complete level 1 verification?</h6>
                  </div>
                </ng-template>
                <ng-template ngbPanelContent>
                    <p class="text-muted mb-0 faq-ans">1. Tap on the “ACCOUNT” button on the dashboard bottom screen.</p><br>
                    <p class="text-muted mb-0 faq-ans">2. Select and tap “ACCOUNT VERIFICATION” from the list.</p><br>
                    <p class="text-muted mb-0 faq-ans">3. Select and tap the Level 1 “LEVEL UP” button to continue.</p><br>
                    <p class="text-muted mb-0 faq-ans">4. Input your active “EMAIL ADDRESS” and then tap VERIFY button.</p><br>
                    <p class="text-muted mb-0 faq-ans">5. Enter the 6-digit OTP that was sent out to your email address.</p><br>
                    <p class="text-muted mb-0 faq-ans">6. Fill out your Identity Information, then tap the CONTINUE button.</p><br>
                    <p class="text-muted mb-0 faq-ans">7. Scan one valid GOVERNMENT ID (Front & Back).</p><br>
                    <p class="text-muted mb-0 faq-ans">8. Take a clear SELFIE photo followed by another SELFIE with an ID photo.</p><br>
                    <p class="text-muted mb-0 faq-ans">9. Wait for our Customer Experience Team to approve your request within 48 hours.</p><br>
                    <p class="text-muted mb-0 faq-ans">10. Verify that you have completed Level 1 (Gold Member) account verification.</p>
                </ng-template>
              </ngb-panel>

              <ngb-panel cardClass="border-0 rounded shadow-md mb-2">
                <ng-template ngbPanelTitle>
                  <div class="border-0 bg-light" id="headingtwo">
                    <h6 class="title mb-0 text-dark">How to view your QR Code?</h6>
                  </div>
                </ng-template>
                <ng-template ngbPanelContent>
                  <p class="text-muted mb-0 faq-ans">1. Login to your myJuan Super app.</p>
                  <p class="text-muted mb-0 faq-ans">2. Tap “MY QR” icon located on your myJuan dashboard screen.</p>
                </ng-template>
              </ngb-panel>

              <ngb-panel cardClass="border-0 rounded shadow-md mb-2">
                <ng-template ngbPanelTitle>
                  <div class="border-0 bg-light" id="headingthree">
                    <h6 class="title mb-0 text-dark">How to Top Up?</h6>
                  </div>
                </ng-template>
                <ng-template ngbPanelContent>
                    <p class="text-muted mb-0 faq-ans">1. Tap the “TOP-UP” icon located on your myJuan dashboard screen.</p><br>
                    <p class="text-muted mb-0 faq-ans">2. Select “OVER-THE-COUNTER” from the list.</p><br>
                    <p class="text-muted mb-0 faq-ans">3. Enter an “AMOUNT” then tap the “REQUEST” button.</p><br>
                    <p class="text-muted mb-0 faq-ans">4. Confirm your transaction by sliding the button to the right.</p><br>
                    <p class="text-muted mb-0 faq-ans">5. To complete the transaction, tap the "SAVE RECEIPT" button and then the close (X) button.</p><br>
                    <p class="text-muted mb-0 faq-ans">6. SHOW RECEIPT to our GoVIP Center Outlet teller located on the ground floor.</p><br>
                </ng-template>
              </ngb-panel>

              <ngb-panel cardClass="border-0 rounded shadow-md mb-2">
                <ng-template ngbPanelTitle>
                  <div class="border-0 bg-light" id="headingthree">
                    <h6 class="title mb-0 text-dark">How to Send/Pay using the App?</h6>
                  </div>
                </ng-template>
                <ng-template ngbPanelContent>
                    <p class="text-muted mb-0 faq-ans">1. Tap the “SEND” icon located on your myJuan dashboard screen.</p><br>
                    <p class="text-muted mb-0 faq-ans">2. Select “SCAN QR CODE” from the list.</p><br>
                    <p class="text-muted mb-0 faq-ans">3. Give myJuan permission to access your camera/gallery/pictures/video.</p><br>
                    <p class="text-muted mb-0 faq-ans">4. Scan/upload the store’s unique QR CODE to capture their account.</p><br>
                    <p class="text-muted mb-0 faq-ans">5. Type in the "AMOUNT" you must send as payment, then tap the “NEXT” button.</p><br>
                    <p class="text-muted mb-0 faq-ans">6. Confirm your transaction by sliding the button to the right.</p><br>
                    <p class="text-muted mb-0 faq-ans">7. To complete the transaction, tap the "SAVE RECEIPT" button and then the close (X) button.</p><br>
                    <p class="text-muted mb-0 faq-ans">8. Verify with the staff at the store whether your payment was accepted.</p><br>
                    <p class="text-muted mb-0 faq-ans">9. Claim your purchased goods.</p><br>
                </ng-template>
              </ngb-panel>

              <ngb-panel cardClass="border-0 rounded shadow-md mb-2">
                <ng-template ngbPanelTitle>
                  <div class="border-0 bg-light" id="headingthree">
                    <h6 class="title mb-0 text-dark">How to send money to another myJuan Member using the App?</h6>
                  </div>
                </ng-template>
                <ng-template ngbPanelContent>
                    <p class="text-muted mb-0 faq-ans">1. Tap the “SEND” icon located on your myJuan dashboard screen.</p><br>
                    <p class="text-muted mb-0 faq-ans">2. Select “SEND MONEY TO ANOTHER MYJUAN MEMBER” from the list.</p><br>
                    <p class="text-muted mb-0 faq-ans">3. Please enter the recipient's 11-digit mobile number and press submit button.</p><br>
                    <p class="text-muted mb-0 faq-ans">4. Type in the "AMOUNT" you will send, then tap the “NEXT” button.</p><br>
                    <p class="text-muted mb-0 faq-ans">5. Confirm your transaction by sliding the button to the right.</p><br>
                    <p class="text-muted mb-0 faq-ans">6. To complete the transaction, tap the "SAVE RECEIPT" button and then the close (X) button.</p><br>
                </ng-template>
              </ngb-panel>

              <ngb-panel cardClass="border-0 rounded shadow-md mb-2">
                <ng-template ngbPanelTitle>
                  <div class="border-0 bg-light" id="headingthree">
                    <h6 class="title mb-0 text-dark">How to send money to another Bank/E-wallet via Vip Cloud?</h6>
                  </div>
                </ng-template>
                <ng-template ngbPanelContent>
                    <p class="text-muted mb-0 faq-ans">1. Tap the “SEND” icon located on your myJuan dashboard screen.</p><br>
                    <p class="text-muted mb-0 faq-ans">2. Select “VIP CLOUD TRANSFER” from the list.</p><br>
                    <p class="text-muted mb-0 faq-ans">3. Select which bank or e-wallet you want to transfer your money.</p><br>
                    <p class="text-muted mb-0 faq-ans">4. Type in the AMOUNT and other required information, then tap the “NEXT” button.</p><br>
                    <p class="text-muted mb-0 faq-ans">5. Confirm your transaction by sliding the button to the right.</p><br>
                    <p class="text-muted mb-0 faq-ans">6. To complete the transaction, tap the "SAVE RECEIPT" button and then the close (X) button.</p><br>
                </ng-template>
              </ngb-panel>

              <ngb-panel cardClass="border-0 rounded shadow-md mb-2">
                <ng-template ngbPanelTitle>
                  <div class="border-0 bg-light" id="headingthree">
                    <h6 class="title mb-0 text-dark">How to send money through scan QR Code?</h6>
                  </div>
                </ng-template>
                <ng-template ngbPanelContent>
                    <p class="text-muted mb-0 faq-ans">1. Tap the “SEND” icon located on your myJuan dashboard screen.</p><br>
                    <p class="text-muted mb-0 faq-ans">2. Select “SCAN QR CODE” from the list.</p><br>
                    <p class="text-muted mb-0 faq-ans">3. Scan/Upload the receiver’s QR code to proceed with the next step</p><br>
                    <p class="text-muted mb-0 faq-ans">4. Type in the "AMOUNT" you will send, then tap the “NEXT” button.</p><br>
                    <p class="text-muted mb-0 faq-ans">5. Confirm your transaction by sliding the button to the right.</p><br>
                    <p class="text-muted mb-0 faq-ans">6. To complete the transaction, tap the "SAVE RECEIPT" button and then the close (X) button.</p><br>
                    
                </ng-template>
              </ngb-panel>

              <ngb-panel cardClass="border-0 rounded shadow-md mb-2">
                <ng-template ngbPanelTitle>
                  <div class="border-0 bg-light" id="headingthree">
                    <h6 class="title mb-0 text-dark">How to pay bills using the App?</h6>
                  </div>
                </ng-template>
                <ng-template ngbPanelContent>
                    <p class="text-muted mb-0 faq-ans">1. Tap the “PAY BILLS” icon located on your myJuan dashboard screen.</p><br>
                    <p class="text-muted mb-0 faq-ans">2. Select either ECPAY or MULTISYS, depending on where your preferred biller is found.</p><br>
                    <p class="text-muted mb-0 faq-ans">3. Type the required info and press the CONTINUE button.</p><br>
                    <p class="text-muted mb-0 faq-ans">4. Confirm your transaction by sliding the button to the right.</p><br>
                    <p class="text-muted mb-0 faq-ans">5. To complete the transaction, tap the "SAVE RECEIPT" button and then the close (X) button.</p><br>
                    
                </ng-template>
              </ngb-panel>

              <ngb-panel cardClass="border-0 rounded shadow-md mb-2">
                <ng-template ngbPanelTitle>
                  <div class="border-0 bg-light" id="headingthree">
                    <h6 class="title mb-0 text-dark">How to cash out your fund using the App?</h6>
                  </div>
                </ng-template>
                <ng-template ngbPanelContent>
                    <p class="text-muted mb-0 faq-ans">1. Tap the “CASH OUT” icon located on your myJuan dashboard screen.</p><br>
                    <p class="text-muted mb-0 faq-ans">2. Tap and select “VIA GOVIP CENTER OUTLET”.</p><br>
                    <p class="text-muted mb-0 faq-ans">3. Enter the “AMOUNT” and tap the “CONTINUE” button.</p><br>
                    <p class="text-muted mb-0 faq-ans">4. Confirm your transaction by sliding the button to the right.</p><br>
                    <p class="text-muted mb-0 faq-ans">5. To complete the transaction, tap the "SAVE RECEIPT" button and then the close (X) button.</p><br>
                    
                </ng-template>
              </ngb-panel>

              <ngb-panel cardClass="border-0 rounded shadow-md mb-2">
                <ng-template ngbPanelTitle>
                  <div class="border-0 bg-light" id="headingthree">
                    <h6 class="title mb-0 text-dark">How to view notification using the App?</h6>
                  </div>
                </ng-template>
                <ng-template ngbPanelContent>
                    <p class="text-muted mb-0 faq-ans">1. Tap the “Envelope” icon located on the top right corner of the dashboard screen.</p><br>
                    
                </ng-template>
              </ngb-panel>

              <ngb-panel cardClass="border-0 rounded shadow-md mb-2">
                <ng-template ngbPanelTitle>
                  <div class="border-0 bg-light" id="headingthree">
                    <h6 class="title mb-0 text-dark">How to view pending transactions using the App?</h6>
                  </div>
                </ng-template>
                <ng-template ngbPanelContent>
                    <p class="text-muted mb-0 faq-ans">1. Tap the “ENVELOPE” icon located on the top right corner of the dashboard screen.</p><br>
                    <p class="text-muted mb-0 faq-ans">2. Tap “PENDING TRANSACTIONS” to view the list of pending transactions.</p><br>
                </ng-template>
              </ngb-panel>

              <ngb-panel cardClass="border-0 rounded shadow-md mb-2">
                <ng-template ngbPanelTitle>
                  <div class="border-0 bg-light" id="headingthree">
                    <h6 class="title mb-0 text-dark">How to view your VipCo Balance?</h6>
                  </div>
                </ng-template>
                <ng-template ngbPanelContent>
                    <p class="text-muted mb-0 faq-ans">1. Login to your myJuan account.</p><br>
                    <p class="text-muted mb-0 faq-ans">2. View your VIPCo balance from the dashboard screen.</p><br>
                </ng-template>
              </ngb-panel>

              <ngb-panel cardClass="border-0 rounded shadow-md mb-2">
                <ng-template ngbPanelTitle>
                  <div class="border-0 bg-light" id="headingthree">
                    <h6 class="title mb-0 text-dark">How to view activity history?</h6>
                  </div>
                </ng-template>
                <ng-template ngbPanelContent>
                    <p class="text-muted mb-0 faq-ans">1. Tap on the “ACTIVITY HISTORY” button on the dashboard bottom screen.</p><br>
                    <p class="text-muted mb-0 faq-ans">2. Tap on the transaction to view transaction details.</p><br>
                    <p class="text-muted mb-0 faq-ans">3. Tap on “VIEW RECEIPT” button to view the receipt of your transaction.</p><br>
                    <p class="text-muted mb-0 faq-ans">4. Tap on “SAVE RECEIPT” button to save the receipt in your phone’s gallery.</p><br>
                </ng-template>
              </ngb-panel>

              <ngb-panel cardClass="border-0 rounded shadow-md mb-2">
                <ng-template ngbPanelTitle>
                  <div class="border-0 bg-light" id="headingthree">
                    <h6 class="title mb-0 text-dark">How to view FAQs from the App?</h6>
                  </div>
                </ng-template>
                <ng-template ngbPanelContent>
                    <p class="text-muted mb-0 faq-ans">1. Tap the letter "I" icon in the top right corner of the screen whenever it appears to view all frequently asked questions about the screen you're on.</p><br>
                </ng-template>
              </ngb-panel>

              <ngb-panel cardClass="border-0 rounded shadow-md mb-2">
                <ng-template ngbPanelTitle>
                  <div class="border-0 bg-light" id="headingthree">
                    <h6 class="title mb-0 text-dark">How to view your linked QR Code?</h6>
                  </div>
                </ng-template>
                <ng-template ngbPanelContent>
                    <p class="text-muted mb-0 faq-ans">1. Tap the account icon at the bottom of the dashboard to open the account profile screen.</p><br>
                    <p class="text-muted mb-0 faq-ans">2. Tap the QR code on the top right corner to view details of your linked GoVIP Center Outlet</p><br>
                </ng-template>
              </ngb-panel>

              <ngb-panel cardClass="border-0 rounded shadow-md mb-2">
                <ng-template ngbPanelTitle>
                  <div class="border-0 bg-light" id="headingthree">
                    <h6 class="title mb-0 text-dark">How to view Membership level?</h6>
                  </div>
                </ng-template>
                <ng-template ngbPanelContent>
                    <p class="text-muted mb-0 faq-ans">1. Tap the account icon at the bottom of the dashboard to open the account profile screen.</p><br>
                    <p class="text-muted mb-0 faq-ans">2. Tap “MEMBERSHIP” to view all details related to a certain level.</p><br>
                </ng-template>
              </ngb-panel>

              <ngb-panel cardClass="border-0 rounded shadow-md mb-2">
                <ng-template ngbPanelTitle>
                  <div class="border-0 bg-light" id="headingthree">
                    <h6 class="title mb-0 text-dark">How to view your Profile?</h6>
                  </div>
                </ng-template>
                <ng-template ngbPanelContent>
                    <p class="text-muted mb-0 faq-ans">1. Tap the account icon at the bottom of the dashboard to open the account profile screen.</p><br>
                    <p class="text-muted mb-0 faq-ans">2. Tap “PROFILE” to view your user profile.</p><br>
                </ng-template>
              </ngb-panel>

              <ngb-panel cardClass="border-0 rounded shadow-md mb-2">
                <ng-template ngbPanelTitle>
                  <div class="border-0 bg-light" id="headingthree">
                    <h6 class="title mb-0 text-dark">How to submit your App feedback?</h6>
                  </div>
                </ng-template>
                <ng-template ngbPanelContent>
                    <p class="text-muted mb-0 faq-ans">1. Tap the account icon at the bottom of the dashboard to open the account profile screen.</p><br>
                    <p class="text-muted mb-0 faq-ans">2. Tap “GIVE FEEDBACK” to proceed to feedback form.</p><br>
                    <p class="text-muted mb-0 faq-ans">3. Drag the circle button to rate your experience from Poor, Neutral to Excellent.</p><br>
                    <p class="text-muted mb-0 faq-ans">4. Put your comments/remarks/suggestions in the provided textfield and tap send feedback.</p><br>
                </ng-template>
              </ngb-panel>

              <ngb-panel cardClass="border-0 rounded shadow-md mb-2">
                <ng-template ngbPanelTitle>
                  <div class="border-0 bg-light" id="headingthree">
                    <h6 class="title mb-0 text-dark">How to view Settings?</h6>
                  </div>
                </ng-template>
                <ng-template ngbPanelContent>
                    <p class="text-muted mb-0 faq-ans">1. Tap the account icon at the bottom of the dashboard to open the account profile screen.</p><br>
                    <p class="text-muted mb-0 faq-ans">2. Tap “SETTINGS” to view and change additional app settings.</p><br>
                </ng-template>
              </ngb-panel>

              <ngb-panel cardClass="border-0 rounded shadow-md mb-2">
                <ng-template ngbPanelTitle>
                  <div class="border-0 bg-light" id="headingthree">
                    <h6 class="title mb-0 text-dark">How to upgrade your membership level?</h6>
                  </div>
                </ng-template>
                <ng-template ngbPanelContent>
                    <p class="text-muted mb-0 faq-ans">1. Tap the account icon at the bottom of the dashboard to open the account profile screen.</p><br>
                    <p class="text-muted mb-0 faq-ans">2. Tap “ACCOUNT VERIFICATION” to view all membership level.</p><br>
                    <p class="text-muted mb-0 faq-ans">3. Select and tap the "LEVEL UP" button to proceed with your level upgrade.</p><br>
                    <p class="text-muted mb-0 faq-ans">4. Follow the step and submit all required requirements.</p><br>
                    <p class="text-muted mb-0 faq-ans">5. Read the instruction provided on the THANK YOU screen and wait for the approval of your application.</p><br>
                </ng-template>
              </ngb-panel>

              <ngb-panel cardClass="border-0 rounded shadow-md mb-2">
                <ng-template ngbPanelTitle>
                  <div class="border-0 bg-light" id="headingthree">
                    <h6 class="title mb-0 text-dark">List of Membership Levels, Requirements and Wallet limits?</h6>
                  </div>
                </ng-template>
                <ng-template ngbPanelContent>
                    <p class="text-muted mb-0 faq-ans">Level 0 (Bronze Member) – Mobile Registration – Daily: 2,000 and Monthly: 10,000</p><br>
                    <p class="text-muted mb-0 faq-ans">Level 1 (Gold Member) – Email and ID Verification – Daily: 50,000 and Monthly: 200,000</p><br>
                    <p class="text-muted mb-0 faq-ans">Level 2 (Platinum) – SOA and Bank Verification – Daily: 100,000 and Monthly: 400,000</p><br>
                    <p class="text-muted mb-0 faq-ans">Level 3 (Diamond) – Business Verification – Daily: 500,000 and Monthly: 5,000,000</p><br>
                    <p class="text-muted mb-0 faq-ans">Level 4 (Phoenix Member) – ACM Sales Account Verification – Daily: 500,000 and Monthly: 5,000,000</p><br>
                    <p class="text-muted mb-0 faq-ans">Level 5 (Dragon Member) – ACM Sales Account Verification – Daily: 1,000,000 and Monthly: 10,000,000</p><br>
                </ng-template>
              </ngb-panel>

              <ngb-panel cardClass="border-0 rounded shadow-md mb-2">
                <ng-template ngbPanelTitle>
                  <div class="border-0 bg-light" id="headingthree">
                    <h6 class="title mb-0 text-dark">How to reset your mobile pin code (MPIN)?</h6>
                  </div>
                </ng-template>
                <ng-template ngbPanelContent>
                    <p class="text-muted mb-0 faq-ans">1. Tap “Forgot MPIN” on the login screen.</p><br>
                    <p class="text-muted mb-0 faq-ans">2. Tap “CONTINUE” button to reset your MPIN.</p><br>
                    <p class="text-muted mb-0 faq-ans">3. Input a valid 6-digit OTP sent to your mobile number.</p><br>
                    <p class="text-muted mb-0 faq-ans">4. Enroll your new 4-digit MPIN</p><br>
                    <p class="text-muted mb-0 faq-ans">5. Re-enter your enrolled 4-digit MPIN</p><br>
                    <p class="text-muted mb-0 faq-ans">6. Tap “DONE” button to complete your setup.</p><br>
                </ng-template>
              </ngb-panel>

              <ngb-panel cardClass="border-0 rounded shadow-md mb-2">
                <ng-template ngbPanelTitle>
                  <div class="border-0 bg-light" id="headingthree">
                    <h6 class="title mb-0 text-dark">How to Sign Out from the App?</h6>
                  </div>
                </ng-template>
                <ng-template ngbPanelContent>
                    <p class="text-muted mb-0 faq-ans">1. Tap the account icon at the bottom of the dashboard to open the account profile screen.</p><br>
                    <p class="text-muted mb-0 faq-ans">2. Tap “SIGN OUT” and tap (YES” button to completely sign out form the app.</p><br>
                </ng-template>
              </ngb-panel>

              <ngb-panel cardClass="border-0 rounded shadow-md mb-2">
                <ng-template ngbPanelTitle>
                  <div class="border-0 bg-light" id="headingthree">
                    <h6 class="title mb-0 text-dark">Troubleshooting Steps</h6>
                  </div>
                </ng-template>
                <ng-template ngbPanelContent>
                    <p class="text-muted mb-0 faq-ans">Step 1:  If you experience any problem, please quit the app and launch it again to see if the technical problem has been fixed.</p><br>
                    <p class="text-muted mb-0 faq-ans">Step 2:  Alternatively, you can contact our IT staff to discuss any technical issues you may have by sending an email to <a href="mailto:it@acmbusinesssolutions.net" class="text-foot list-unstyled">it@acmbusinesssolutions.net</a>.</p><br>
                </ng-template>
              </ngb-panel>

            </ngb-accordion>
          </div>
        </div>

        
        <!--end col-->
      </div>
      <!--end row-->
    </div>
    <!--end container-->
    <div class="container pb-5">
        <ul class="list-unstyled text-muted">
            <i-feather name="arrow-left-circle" class="fea icon-sm text-primary me-2"></i-feather>
            <a href="#">Go Back to Homepage</a>
        </ul>

    </div>
  </section>

 

  <app-footer></app-footer> 