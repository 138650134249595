<app-header></app-header>

<section id="" class="pt-5 mt-5">
    <div class="container">
      <!--end row-->
      <div class="row align-items-center">
        
        <!--end col-->
  
        <div class="col-md-8 col-12">
          <div class="section-title ms-lg-5">
            <h4 class="title mb-4">EXPRESS PAY</h4>
              <br>
            
            <p class="text-muted para-desc mb-0 mx-auto lh-lg int">Payments can be time-consuming when doing it by going through payment centers. With our myJuan Super App, payments are a lot easier and safer. You can monitor your transactions that has been completely made. Rebates can be earned by using this service. 

            </p>
          </div>
        </div>
        <!--end col-->
        <div class="col-lg-4 col-md-4 col-12 mt-4 pt-2 text-center text-md-end">
          <img src="assets/images/app/pay.png" class="img-fluid mx-auto d-block" alt="">
        </div>
      </div>
      <!--end row-->
    </div>
    <!--end container-->
  
  
    <!--end container-->
  </section>
  <br><br>
  <section>
      <div class="container">
        <div class="row">
    
          <div class="col">
            <div class="section-title" id="">
              <h4>FAQs</h4>
            </div>
            <div class="faq-content mt-4 pt-2 pb-5">
              <ngb-accordion #acc="ngbAccordion" activeIds="ngb-panel-0" closeOthers="true">
                <ngb-panel id="ngb-panel-0" cardClass="border-0 rounded shadow mb-2">
                  <ng-template ngbPanelTitle>
                    <div class="border-0 bg-light" id="headingone">
                      <h6 class="title mb-0 text-dark">What is Express Pay?</h6>
                    </div>
                  </ng-template>
                  <ng-template ngbPanelContent>
                    <p class="text-muted mb-0 faq-ans">Express Pay is a service that allows you to pay your bills from a wide range of billers. Everything about payments and billers may be found here.</p>
                  </ng-template>
                </ngb-panel>
                <ngb-panel cardClass="border-0 rounded shadow mb-2">
                  <ng-template ngbPanelTitle>
                    <div class="border-0 bg-light" id="headingtwo">
                      <h6 class="title mb-0 text-dark">How can I use Express Pay to pay my bills?</h6>
                    </div>
                  </ng-template>
                  <ng-template ngbPanelContent>
                    <p class="text-muted mb-0 faq-ans">myJuan Super Apsp is introducing the bills payment tool to provide you with a quick, secure, and convenient option to pay your bills using your Vipco Wallet. Simply choose your biller, input your account information and bill amount, and pay with your Vipco Wallet!</p>
                  </ng-template>
                </ngb-panel>
                <ngb-panel cardClass="border-0 rounded shadow mb-2">
                  <ng-template ngbPanelTitle>
                    <div class="border-0 bg-light" id="headingthree">
                      <h6 class="title mb-0 text-dark">How long does it take for my payment to show up in my biller's account?</h6>
                    </div>
                  </ng-template>
                  <ng-template ngbPanelContent>
                    <p class="text-muted mb-0 faq-ans">All myJuan Super App payments are processed in real time. Payment posting to your designated billers may differ accordingly. Typically, airtime loading, selected government payments, and Cash Ins are posted/transferred in real time.
                    </p>
                  </ng-template>
                </ngb-panel>
                <ngb-panel cardClass="border-0 rounded shadow mb-2">
                    <ng-template ngbPanelTitle>
                      <div class="border-0 bg-light" id="headingthree">
                        <h6 class="title mb-0 text-dark">Can I pay my bills directly using a credit card?</h6>
                      </div>
                    </ng-template>
                    <ng-template ngbPanelContent>
                      <p class="text-muted mb-0 faq-ans">No, only your Vips Wallet may be used to make bill payments.</p>
                    </ng-template>
                  </ngb-panel>
              </ngb-accordion>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end container-->
      <div class="container pb-5">
          <ul class="list-unstyled text-muted">
              <i-feather name="arrow-left-circle" class="fea icon-sm text-primary me-2"></i-feather>
              <a href="#">Go Back to Homepage</a>
          </ul>
  
      </div>
    </section>
  
   
  
    <app-footer></app-footer> 