<app-header></app-header>

<section id="" class="pt-5 mt-5">
    <div class="container">
      <!--end row-->
      <div class="row align-items-center">
        
        <!--end col-->
  
        <div class="col-md-8 col-12">
          <div class="section-title ms-lg-5">
            <h4 class="title mb-4">EXPRESS BUY</h4>
              <br>
            <p class="text-muted para-desc mb-0 mx-auto lh-lg int">This service allows app users to purchase food  with our partner merchants available inside our myJuan Super App. Users can also use this service to send food items to an individual. Rebates can also be earned through this service.</p>
  
          </div>
        </div>
        <!--end col-->
        <div class="col-lg-4 col-md-4 col-12 mt-4 pt-2 text-center text-md-end">
          <img src="assets/images/app/buy.png" class="img-fluid mx-auto d-block" alt="">
        </div>
      </div>
      <!--end row-->
    </div>
    <!--end container-->
  
  
    <!--end container-->
  </section>
  <br><br>
  <section>
      <div class="container">
        <div class="row">
    
          <div class="col">
            <div class="section-title" id="">
              <h4>FAQs</h4>
            </div>
            <div class="faq-content mt-4 pt-2 pb-5">
              <ngb-accordion #acc="ngbAccordion" activeIds="ngb-panel-0" closeOthers="true">
                <ngb-panel id="ngb-panel-0" cardClass="border-0 rounded shadow mb-2">
                  <ng-template ngbPanelTitle>
                    <div class="border-0 bg-light" id="headingone">
                      <h6 class="title mb-0 text-dark">What is Express Buy?</h6>
                    </div>
                  </ng-template>
                  <ng-template ngbPanelContent>
                    <p class="text-muted mb-0 faq-ans">Express Buy lets users book food deliveries from a partnered merchant.</p>
                  </ng-template>
                </ngb-panel>
                
                
                
                  <ngb-panel cardClass="border-0 rounded shadow mb-2">
                    <ng-template ngbPanelTitle>
                      <div class="border-0 bg-light" id="headingthree">
                        <h6 class="title mb-0 text-dark">What kind of food can I order at Express Food?</h6>
                      </div>
                    </ng-template>
                    <ng-template ngbPanelContent>
                        <p class="text-muted mb-0 faq-ans">A wide range of food options is offered including freshly cooked meals, sandwiches, desserts, and drinks that are sure to fulfill your demands at any time of day or night. Discover new local food stores as we continue to develop and bring more options and choices.</p>
                    </ng-template>
                  </ngb-panel>
                  <ngb-panel cardClass="border-0 rounded shadow mb-2">
                    <ng-template ngbPanelTitle>
                      <div class="border-0 bg-light" id="headingthree">
                        <h6 class="title mb-0 text-dark">Where do you make deliveries?</h6>
                      </div>
                    </ng-template>
                    <ng-template ngbPanelContent>
                        <p class="text-muted mb-0 faq-ans">Accessible across Luzon as well as in select portions of the Visayas and Mindanao Regions.</p>
                    </ng-template>
                  </ngb-panel>
                  <ngb-panel cardClass="border-0 rounded shadow mb-2">
                    <ng-template ngbPanelTitle>
                      <div class="border-0 bg-light" id="headingthree">
                        <h6 class="title mb-0 text-dark">What are the delivery options?</h6>
                      </div>
                    </ng-template>
                    <ng-template ngbPanelContent>
                        <p class="text-muted mb-0 faq-ans">Orders will be delivered by our Vips Riders, our in-house delivery service.</p>
                    </ng-template>
                  </ngb-panel>
                  <ngb-panel cardClass="border-0 rounded shadow mb-2">
                    <ng-template ngbPanelTitle>
                      <div class="border-0 bg-light" id="headingthree">
                        <h6 class="title mb-0 text-dark">What restaurants in my area provide Express Food delivery?</h6>
                      </div>
                    </ng-template>
                    <ng-template ngbPanelContent>
                        <p class="text-muted mb-0 faq-ans">Simply input your address to check which restaurants are available for delivery. We limit the available restaurants to those within a certain radius from your area to keep your food fresh and assure timely deliveries.</p>
                    </ng-template>
                  </ngb-panel>
                  <ngb-panel cardClass="border-0 rounded shadow mb-2">
                    <ng-template ngbPanelTitle>
                      <div class="border-0 bg-light" id="headingthree">
                        <h6 class="title mb-0 text-dark">Do I earn rebates?</h6>
                      </div>
                    </ng-template>
                    <ng-template ngbPanelContent>
                        <p class="text-muted mb-0 faq-ans">Yes. You earn rebates whenever you use this service.</p>
                    </ng-template>
                  </ngb-panel>
                  <ngb-panel cardClass="border-0 rounded shadow mb-2">
                    <ng-template ngbPanelTitle>
                      <div class="border-0 bg-light" id="headingthree">
                        <h6 class="title mb-0 text-dark">Can I pay using my myJuan wallet?</h6>
                      </div>
                    </ng-template>
                    <ng-template ngbPanelContent>
                        <p class="text-muted mb-0 faq-ans">You can use your Vipco wallet to pay for this service.</p>
                    </ng-template>
                  </ngb-panel>
              </ngb-accordion>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end container-->
      <div class="container pb-5">
          <ul class="list-unstyled text-muted">
              <i-feather name="arrow-left-circle" class="fea icon-sm text-primary me-2"></i-feather>
              <a href="#">Go Back to Homepage</a>
          </ul>
  
      </div>
    </section>
  
   
  
    <app-footer></app-footer> 