<app-header></app-header>

<section id="" class="pt-5 mt-5">
    <div class="container">
      <!--end row-->
      <div class="row align-items-center">
        
        <!--end col-->
  
        <div class="col-md-8 col-12">
          <div class="section-title ms-lg-5">
            <h4 class="title mb-4">EXPRESS EXCHANGE</h4>
              <br>
            
            <p class="text-muted para-desc mb-0 mx-auto lh-lg int">Cryptocurrency is not that new to us these days. It has been present for many years and is now in demand on the market. Cryptocurrency can be used to invest and save, that's why myJuan App launches this service where you can buy and sell cryptocurrency.
            </p>
          </div>
        </div>
        <!--end col-->
        <div class="col-lg-4 col-md-4 col-12 mt-4 pt-2 text-center text-md-end">
          <img src="assets/images/app/exchange.png" class="img-fluid mx-auto d-block" alt="">
        </div>
      </div>
      <!--end row-->
    </div>
    <!--end container-->
  
  
    <!--end container-->
  </section>
  <br><br>
  <section>
      <div class="container">
        <div class="row">
    
          <div class="col">
            <div class="section-title" id="">
              <h4>FAQs</h4>
            </div>
            <div class="faq-content mt-4 pt-2 pb-5">
              <ngb-accordion #acc="ngbAccordion" activeIds="ngb-panel-0" closeOthers="true">
                <ngb-panel id="ngb-panel-0" cardClass="border-0 rounded shadow mb-2">
                  <ng-template ngbPanelTitle>
                    <div class="border-0 bg-light" id="headingone">
                      <h6 class="title mb-0 text-dark">What is Express Exchange?</h6>
                    </div>
                  </ng-template>
                  <ng-template ngbPanelContent>
                    <p class="text-muted mb-0 faq-ans">The myJuan App introduces this feature, which allows you to convert your myJuan funds to cryptocurrency and vice versa.</p>
                  </ng-template>
                </ngb-panel>
                <ngb-panel cardClass="border-0 rounded shadow mb-2">
                  <ng-template ngbPanelTitle>
                    <div class="border-0 bg-light" id="headingtwo">
                      <h6 class="title mb-0 text-dark">How do I exchange my myJuan funds to cryptocurrency?</h6>
                    </div>
                  </ng-template>
                  <ng-template ngbPanelContent>
                    <p class="text-muted mb-0 faq-ans">You can exchange your myJuan Funds by choosing an available cryptocurrency.</p>
                  </ng-template>
                </ngb-panel>
              </ngb-accordion>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end container-->
      <div class="container pb-5">
          <ul class="list-unstyled text-muted">
              <i-feather name="arrow-left-circle" class="fea icon-sm text-primary me-2"></i-feather>
              <a href="#">Go Back to Homepage</a>
          </ul>
  
      </div>
    </section>
  
   
  
    <app-footer></app-footer> 