
<section class="bg-half bg-light d-table w-100" id="home">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-12 text-center">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-lg-7 col-12">
              <div class="rounded p-4 shadow">
                <div class="row">
                  <div class="col-12">
                    <form [formGroup]="joinUsForm" (ngSubmit)="onSubmit(submissionSuccessModal)">
                      <div class="row">
                        <div class="col-md-6">
                          <div class="mb-3">
                            <label class="form-label">Full Name <span class="text-danger">*</span></label>
                            <div class="form-icon position-relative">
                              <!-- <i class="uil uil-user position-absolute top-50 translate-middle-y text-primary" style="left: 10px;"></i> -->
                              <input formControlName="fullname" name="fullname" id="fullname" type="text" class="form-control" placeholder="Full Name :" 
                              [ngClass]="{ 'is-invalid': submitted && form['fullname'].errors }">
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="mb-3">
                            <label class="form-label">Your Email <span class="text-danger">*</span></label>
                            <div class="form-icon position-relative">
                              <!-- <i class="uil uil-envelope position-absolute top-50 translate-middle-y text-primary" style="left: 10px;"></i> -->
                              <input formControlName="email"  name="email" id="email" type="email" class="form-control" placeholder="Email :"
                              [ngClass]="{ 'is-invalid': submitted && form['email'].errors }">
                            </div>
                          </div>
                        </div>
                        <!--end col-->
                        <div class="col-md-12">
                          <div class="mb-3">
                            <label class="form-label">Subject</label>
                            <div class="form-icon position-relative">
                              <!-- <i class="uil uil-book position-absolute top-50 translate-middle-y text-primary" style="left: 10px;"></i> -->
                              <input formControlName="subject" name="subject" id="subject" class="form-control" placeholder="Subject :"
                              [ngClass]="{ 'is-invalid': submitted && form['subject'].errors }">
                            </div>
                          </div>
                        </div>
                        <!--end col-->
                        <div class="col-md-12">
                          <div class="mb-3">
                            <label class="form-label">Comments <span class="text-danger">*</span></label>
                            <div class="form-icon position-relative">
                              <!-- <i class="uil uil-comment-alt position-absolute top-10 translate-middle-y text-primary" style="left: 10px; top: 20px;"></i> -->
                              <textarea formControlName="comments" name="comments" id="comments" rows="4" class="form-control" placeholder="Message :"
                              [ngClass]="{ 'is-invalid': submitted && form['comments'].errors }"></textarea>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!--end row-->
                      <div class="row">
                        <div class="col-sm-12">
                          <div class="title">
                            <p class="text-muted">You may request to permanently delete your account details from our system. All the stored data related to your account will be permanently deleted from our system. Please give us 3-5 business days to process your request.
                            </p>
                          </div>
                        </div>
                        <div class="col-sm-12">
                          <input type="submit" id="submit" name="send" class="btn btn-primary" value="Submit">
                        </div>
                        <!--end col-->
                      </div>
                      <!--end row-->
                    </form>
                    <!--end form-->
                  </div>
                  <!--end col-->
                </div>
                <!--end row-->
              </div>
            </div>
            <!--end col-->
          </div>
          <!--end row-->
        </div>
        <!-- <div class="page-next-level"> -->
        

          <!-- <div class="page-next"> -->
            <!-- <nav aria-label="breadcrumb" class="d-inline-block"> -->
              <!-- <ul class="breadcrumb bg-white rounded shadow mb-0">
                <li class="breadcrumb-item"><a routerLink="/index">Landrick</a></li>
                <li class="breadcrumb-item"><a href="javascript:void(0)">Help Center</a></li>
                <li class="breadcrumb-item active" aria-current="page">Support</li>
              </ul> -->
            <!-- </nav> -->
          </div>
        </div>
      </div>
      <!--end col-->
    <!-- </div> -->
    <!--end row-->
  <!-- </div> -->
  <!--end container-->
</section>
<!--end section-->
<!-- Hero End -->

<!-- Start Section -->
<!-- <section class="section"> -->
  
  <!--end container-->

  <!-- <div class="container mt-100 mt-60">
    <div class="row">
      <div class="col-lg-6 col-md-6 col-12">
        <div class="d-flex align-items-center features feature-clean shadow rounded p-4">
          <div class="icons text-primary text-center">
            <i class="uil uil-envelope-check d-block rounded h3 mb-0"></i>
          </div>
          <div class="flex-1 content ms-4">
            <h5 class="mb-1"><a href="javascript:void(0)" class="text-dark">Get in Touch !</a></h5>
            <p class="text-muted mb-0">This is required when, for text is not yet available.</p>
            <div class="mt-2">
              <a href="javascript:void(0)" class="btn btn-sm btn-soft-primary">Submit a Request</a>
            </div>
          </div>
        </div>
      </div> -->
      <!--end col-->

      <!-- <div class="col-lg-6 col-md-6 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
        <div class="d-flex align-items-center features feature-clean shadow rounded p-4">
          <div class="icons text-primary text-center">
            <i class="uil uil-webcam d-block rounded h3 mb-0"></i>
          </div>
          <div class="flex-1 content ms-4">
            <h5 class="mb-1"><a href="javascript:void(0)" class="text-dark">Start a Meeting</a></h5>
            <p class="text-muted mb-0">This is required when, for text is not yet available.</p>
            <div class="mt-2">
              <a href="javascript:void(0)" class="btn btn-sm btn-soft-primary">Start Video Chat</a>
            </div>
          </div>
        </div>
      </div> -->
      <!--end col-->
    <!-- </div> -->
    <!--end row-->
  <!-- </div> -->
  <!--end container-->
<!-- </section> -->
<!--end section-->
<!-- End Section -->

<ng-template #submissionSuccessModal let-modal>
  <div class="modal-header">
    <h6 class="modal-title text-success">
      Thank You for Reaching Out!
    </h6>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <p>
      We've received your request and appreciated you contacting us. Our Support Team will review your message and get back to you as soon as possible.
    </p>
    <p>
      If you've requested to permanently delete your account, please wait for 3-5 business days.
    </p>
    <div class="d-flex justify-content-center">
      <button class="btn btn-primary float-right" (click)="modal.close('Close click')">Close</button>
    </div>
  </div>
</ng-template>

<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
  <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->
