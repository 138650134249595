<img src="assets/images/app/cool-background2.svg" class="position-absolute container-fluid img002" alt="" height="100%">
<app-header></app-header>
<section id="" class="pt-5 mt-5">
  <div class="container">
    <div class="btn-group btn-group-toggle container px-5" data-toggle="buttons">
        <label class="btn btn-secondary">
           <a href="/faqs-vips">myJuan Super App FAQs</a>
        </label>
        <label class="btn btn-secondary active">
            <a href="/faqs-vips-merchant" routerLink="/faqs-vips-merchant">myJuan Merchant App FAQs</a>
          </label>
        <label class="btn btn-secondary">
            <a href="/faqs-vips-rider">myJuan Rider App FAQs</a>
        </label>
      </div>
    <!--end row-->
    <div class="row align-items-center">
      
      <!--end col-->

      <div class="col-md-8 col-12">
        <div class="title ms-lg-5">
          <h4 class="title mb-4 text-primary h2">myJuan Merchant App</h4>
          <p class="text-muted mb-4">A myJuan Merchant account will help your store get noticed and bring in orders. Creating your own platform will allow you to market more effectively.</p>
            <br>
        </div>
      </div>
      <!--end col-->
     <div class="col-lg-4 col-md-4 col-12 mt-4 pt-2 text-center text-md-end">
        <img src="../../../../assets/myjuan/Merchant.png" class="img-fluid mx-auto d-block" alt="">
      </div>
    </div>
    <!--end row-->
  </div>
  <!--end container-->

<br><br>
  <!--end container-->
</section><br><br><br>
<img src="assets/images/app/cool-background5.png" class="position-absolute container-fluid img001" alt="">
<img src="assets/images/app/cool-background5.png" class="position-absolute container-fluid img003" alt="">
<section class="position-relative">
    <div class="container">
      <div class="row">
  
        <div class="col">
          <div class="section-title" id="">
            <h4>FAQs</h4>
          </div>
          <div class="faq-content mt-4 pt-2 pb-5">
            <ngb-accordion #acc="ngbAccordion" activeIds="ngb-panel-0" closeOthers="true">
              <ngb-panel id="ngb-panel-0" cardClass="border-0 rounded shadow-md mb-2">
                <ng-template ngbPanelTitle>
                  <div class="border-0 bg-light" id="headingone">
                    <h6 class="title mb-0 text-dark">How to download myJuan Merchant via Play Store?</h6>
                  </div>
                </ng-template>
                <ng-template ngbPanelContent>
                  <p class="text-muted mb-0 faq-ans">1. Open the Play Store app on your smartphone and search for “myJuan Merchant”.</p><br>
                  <p class="text-muted mb-0 faq-ans">2. Tap download, once completed open the app, and continue with the registration.</p>
                </ng-template>
              </ngb-panel>
      
              <ngb-panel cardClass="border-0 rounded shadow-md mb-2">
                <ng-template ngbPanelTitle>
                  <div class="border-0 bg-light" id="headingthree">
                    <h6 class="title mb-0 text-dark">How to register?</h6>
                  </div>
                </ng-template>
                <ng-template ngbPanelContent>
                    <p class="text-muted mb-0 faq-ans">1. Enter your preferred 11-digit mobile number.</p><br>
                    <p class="text-muted mb-0 faq-ans">2. Verify your mobile number and enter your 6-digit OTP.</p><br>
                    <p class="text-muted mb-0 faq-ans">3. Fill out your Basic Information to verify your identity.</p><br>
                    <p class="text-muted mb-0 faq-ans">4. Set up your 4-digit MPIN code to protect your account.</p><br>
                    <p class="text-muted mb-0 faq-ans">5. Scan/Upload the QR CODE or enter the reference code of your preferred JuanPay outlet to link your account.</p>
                </ng-template>
              </ngb-panel>

              <ngb-panel cardClass="border-0 rounded shadow-md mb-2">
                <ng-template ngbPanelTitle>
                  <div class="border-0 bg-light" id="headingthree">
                    <h6 class="title mb-0 text-dark">How to complete merchant registration?</h6>
                  </div>
                </ng-template>
                <ng-template ngbPanelContent>
                    <p class="text-muted mb-0 faq-ans">1. Tap the “Enroll Now!” button located on your myJuan merchant dashboard screen.</p><br>
                    <p class="text-muted mb-0 faq-ans">2. Tap “Continue” button to view Terms and Conditions.</p><br>
                    <p class="text-muted mb-0 faq-ans">3. Read and tap “Agree and Continue” button to proceed to email validation.</p><br>
                    <p class="text-muted mb-0 faq-ans">4. Enter a valid email address and tap “Verify” button.</p><br>
                    <p class="text-muted mb-0 faq-ans">5. Enter a valid 6-digit email OTP to verify your email address.</p><br>
                    <p class="text-muted mb-0 faq-ans">6. Fill out all required fields in the User Information screen and tap “Next” button once done.</p><br>
                    <p class="text-muted mb-0 faq-ans">7. Tap “Next” button on the Identity Verification screen</p><br>
                    <p class="text-muted mb-0 faq-ans">8. Tap and select the “Type of ID” that you need to submit.</p><br>
                    <p class="text-muted mb-0 faq-ans">9. Scan/Upload your ID’s front and back photo and tap “Next” button to continue.</p><br>
                    <p class="text-muted mb-0 faq-ans">11. Scan/Upload your Selfie and Selfie with ID photo and tap “Next” button to continue.</p><br>
                    <p class="text-muted mb-0 faq-ans">12. Upload your store logo.</p><br>
                    <p class="text-muted mb-0 faq-ans">13. Fill out all required fields in the Business Information screen and tap “Next” button.</p><br>
                    <p class="text-muted mb-0 faq-ans">14. Setup you store hours and tap “Next” button.</p><br>
                    <p class="text-muted mb-0 faq-ans">15. Wait for our Customer Experience Team to approve your request within 48 hours.</p><br>
                    <p class="text-muted mb-0 faq-ans">16. Tap “Go to dashboard” button to complete your application request.</p>

                </ng-template>
              </ngb-panel>

              <ngb-panel cardClass="border-0 rounded shadow-md mb-2">
                <ng-template ngbPanelTitle>
                  <div class="border-0 bg-light" id="headingtwo">
                    <h6 class="title mb-0 text-dark">Merchant registration requirements</h6>
                  </div>
                </ng-template>
                <ng-template ngbPanelContent>
                  <p class="text-muted mb-0 faq-ans">1. Store Logo/Icon</p>
                  <p class="text-muted mb-0 faq-ans">2. Business Name</p>
                  <p class="text-muted mb-0 faq-ans">3. Business Address</p>
                  <p class="text-muted mb-0 faq-ans">4. Food Category</p>
                  <p class="text-muted mb-0 faq-ans">5. Food Tags</p>
                  <p class="text-muted mb-0 faq-ans">6. Long/Lat</p>
                  <p class="text-muted mb-0 faq-ans">7. Store Facade Photo</p>
                </ng-template>
              </ngb-panel>

              <ngb-panel cardClass="border-0 rounded shadow-md mb-2">
                <ng-template ngbPanelTitle>
                  <div class="border-0 bg-light" id="headingthree">
                    <h6 class="title mb-0 text-dark">How to set up your store products?</h6>
                  </div>
                </ng-template>
                <ng-template ngbPanelContent>
                    <p class="text-muted mb-0 faq-ans">1. Tap “My Store” logo on the bottom part of the dashboard.</p><br>
                    <p class="text-muted mb-0 faq-ans">2. Tap “Manage Categories” to add your food categories then tap “Add Category”.</p><br>
                    <p class="text-muted mb-0 faq-ans">3. Enter your category name and descriptions then tap “Confirm”. (Repeat this step when necessary)</p><br>
                    <p class="text-muted mb-0 faq-ans">4. Tap the back button and tap “Manage Products”.</p><br>
                    <p class="text-muted mb-0 faq-ans">5. Tap “Add Product” button to start adding all appropriate products in your categories.</p><br>
                    <p class="text-muted mb-0 faq-ans">6.	Upload your product photo.</p><br>
                    <p class="text-muted mb-0 faq-ans">7.	Fill out all required fields and tap “Save”. (Repeat this step when necessary)</p><br>
                    <p class="text-muted mb-0 faq-ans">8.	Tap “Pencil” icon if you need to make changes.</p><br>
                    <p class="text-muted mb-0 faq-ans">9.	Tap “Delete” icon if you need to delete the product including all </p><br>
                    <p class="text-muted mb-0 faq-ans">10.	Toggle the “Active” button on and off if you need to change the product availability.</p><br>
                    <p class="text-muted mb-0 faq-ans">11.	Tap “Add-Options” button to add your specific product add-ons. (Repeat this step when necessary)</p><br>
                    <p class="text-muted mb-0 faq-ans">12.	Enter product “Option Name” (refers to an additional option for the food selected).</p><br>
                    <p class="text-muted mb-0 faq-ans">13.	Enter product “Condition” (e.g. Select One, Choose Two, Pick Three).</p><br>
                    <p class="text-muted mb-0 faq-ans">14.	Toggle the “Required” button if the selected add-on is required or not.</p><br>
                    <p class="text-muted mb-0 faq-ans">15.	Toggle the “Multiple” button if the selection is multiple.</p><br>
                    <p class="text-muted mb-0 faq-ans">16.	Tap “Add Choices” to enter your add-on options. (Repeat this step when necessary)</p><br>
                    <p class="text-muted mb-0 faq-ans">17.	Tap “Remove Choices” if you want to remove the last added add-on.</p><br>
                    <p class="text-muted mb-0 faq-ans">18.	Tap “Confirm” button once completed.</p><br>
                    <p class="text-muted mb-0 faq-ans">19.	Tap the back button and then tap “Manage Banners”.</p><br>
                    <p class="text-muted mb-0 faq-ans">20.	Tap “Add Banner” to add a store banner.</p><br>
                    <p class="text-muted mb-0 faq-ans">21.	Tap the back button and then tap “Publish Store” button.</p><br>
                    <p class="text-muted mb-0 faq-ans">22.	Tap “Submit” button then tap “Yes” button once you are ready to submit all your store products for review.</p><br>
                    <p class="text-muted mb-0 faq-ans">23.	Tap “In Review” and check if your “Store Application is in Review”.</p><br>
                    <p class="text-muted mb-0 faq-ans">24.	You will receive a notification showing “Store Published Successfully!” once your application has been approved.</p><br>
                    <p class="text-muted mb-0 faq-ans">25.	Return to the Publish Store and then tap In Review. Then, tap "Publish Store" and then "Yes" to publish your store's contents completely.</p><br>

                </ng-template>
              </ngb-panel>

              <ngb-panel cardClass="border-0 rounded shadow-md mb-2">
                <ng-template ngbPanelTitle>
                  <div class="border-0 bg-light" id="headingthree">
                    <h6 class="title mb-0 text-dark">How to view your linked QR code?</h6>
                  </div>
                </ng-template>
                <ng-template ngbPanelContent>
                    <p class="text-muted mb-0 faq-ans">1. Tap the account icon at the bottom of the dashboard to open the account profile screen.</p><br>
                    <p class="text-muted mb-0 faq-ans">2. Tap the QR code on the top right corner to view details of your linked JuanPay Outlet</p><br>
                </ng-template>
              </ngb-panel>

              <ngb-panel cardClass="border-0 rounded shadow-md mb-2">
                <ng-template ngbPanelTitle>
                  <div class="border-0 bg-light" id="headingthree">
                    <h6 class="title mb-0 text-dark">How to view notification using the app?</h6>
                  </div>
                </ng-template>
                <ng-template ngbPanelContent>
                    <p class="text-muted mb-0 faq-ans">1. Tap the “Envelope” icon on the dashboard screen's top right corner.</p><br>
                </ng-template>
              </ngb-panel>

              <ngb-panel cardClass="border-0 rounded shadow-md mb-2">
                <ng-template ngbPanelTitle>
                  <div class="border-0 bg-light" id="headingthree">
                    <h6 class="title mb-0 text-dark">How to view your VIPCo balance?</h6>
                  </div>
                </ng-template>
                <ng-template ngbPanelContent>
                    <p class="text-muted mb-0 faq-ans">1.	Login to your myJuan account.</p><br>
                    <p class="text-muted mb-0 faq-ans">2. View your VIPCo balance from the dashboard screen.</p><br>
                </ng-template>
              </ngb-panel>

              <ngb-panel cardClass="border-0 rounded shadow-md mb-2">
                <ng-template ngbPanelTitle>
                  <div class="border-0 bg-light" id="headingthree">
                    <h6 class="title mb-0 text-dark">How to reset your mobile pin code (MPIN)?</h6>
                  </div>
                </ng-template>
                <ng-template ngbPanelContent>
                    <p class="text-muted mb-0 faq-ans">1. Tap “Forgot MPIN” on the login screen.</p><br>
                    <p class="text-muted mb-0 faq-ans">2. Tap “CONTINUE” button to reset your MPIN.</p><br>
                    <p class="text-muted mb-0 faq-ans">3. Input a valid 6-digit OTP sent to your mobile number.</p><br>
                    <p class="text-muted mb-0 faq-ans">4. Enroll your new 4-digit MPIN</p><br>
                    <p class="text-muted mb-0 faq-ans">5. Re-enter your enrolled 4-digit MPIN</p><br>
                    <p class="text-muted mb-0 faq-ans">6. Tap “DONE” button to complete your setup.</p><br>              
                </ng-template>
              </ngb-panel>

              <ngb-panel cardClass="border-0 rounded shadow-md mb-2">
                <ng-template ngbPanelTitle>
                  <div class="border-0 bg-light" id="headingthree">
                    <h6 class="title mb-0 text-dark">How to sign out from the app?</h6>
                  </div>
                </ng-template>
                <ng-template ngbPanelContent>
                    <p class="text-muted mb-0 faq-ans">1. Tap the account icon at the bottom of the dashboard to open the account profile screen.</p><br>
                    <p class="text-muted mb-0 faq-ans">2. Tap “SIGN OUT” and tap (YES” button to completely sign out form the app.</p><br>
                </ng-template>
              </ngb-panel>

              <ngb-panel cardClass="border-0 rounded shadow-md mb-2">
                <ng-template ngbPanelTitle>
                  <div class="border-0 bg-light" id="headingthree">
                    <h6 class="title mb-0 text-dark">Troubleshooting Steps</h6>
                  </div>
                </ng-template>
                <ng-template ngbPanelContent>
                    <p class="text-muted mb-0 faq-ans">Step 1:  If you experience any technical issues, please quit the app and launch it again to see if the technical problem has been fixed.</p><br>
                    <p class="text-muted mb-0 faq-ans">Step 2:  Alternatively, you can contact our IT staff to discuss any technical issues you may have by sending an email to <a href="mailto:it@acmbusinesssolutions.net" class="text-foot list-unstyled">it@acmbusinesssolutions.net</a>.</p><br>
                </ng-template>
              </ngb-panel>

            </ngb-accordion>
          </div>
        </div>

        
        <!--end col-->
      </div>
      <!--end row-->
    </div>
    <!--end container-->
    <div class="container pb-5">
        <ul class="list-unstyled text-muted">
            <i-feather name="arrow-left-circle" class="fea icon-sm text-primary me-2"></i-feather>
            <a href="#">Go Back to Homepage</a>
        </ul>

    </div>
  </section>
  <app-footer></app-footer> 