<img src="assets/images/app/cool-background2.svg" class="position-absolute container-fluid img002" alt="" height="100%">
<app-header></app-header>
<section id="" class="pt-5 mt-5">
  <div class="container">
    <div class="btn-group btn-group-toggle container px-5" data-toggle="buttons">
        <label class="btn btn-secondary">
           <a href="/faqs-vips">myJuan Super App FAQs</a>
        </label>
        <label class="btn btn-secondary">
            <a href="/faqs-vips-merchant" routerLink="//faqs-vips-merchant">myJuan Merchant App FAQs</a>
          </label>
        <label class="btn btn-secondary active">
            <a href="/faqs-vips-rider">myJuan Rider App FAQs</a>
        </label>
      </div>
    <!--end row-->
    <div class="row align-items-center">
      
      <!--end col-->

      <div class="col-md-8 col-12">
        <div class="title ms-lg-5">
          <h4 class="title mb-4 text-primary h2">myJuan Rider app</h4>
          <p class="text-muted mb-4">There's always a hungry customer to satisfy. Every day, myJuan Riders bring smiles to millions of customers. So sign up now, get your gears, and hit the road.</p>
            <br>
        </div>
      </div>
      <!--end col-->
     <div class="col-lg-4 col-md-4 col-12 mt-4 pt-2 text-center text-md-end">
        <img src="../../../../assets/myjuan/Rider.png" class="img-fluid mx-auto d-block" alt="">
      </div>
    </div>
    <!--end row-->
  </div>
  <!--end container-->

<br><br>
  <!--end container-->
</section><br><br><br>
<img src="assets/images/app/cool-background5.png" class="position-absolute container-fluid img001" alt="">
<img src="assets/images/app/cool-background5.png" class="position-absolute container-fluid img003" alt="">
<img src="assets/images/app/cool-background5.png" class="position-absolute container-fluid img004" alt="">
<section class="position-relative">
    <div class="container">
      <div class="row">
  
        <div class="col">
          <div class="section-title" id="">
            <h4>FAQs</h4>
          </div>
          <div class="faq-content mt-4 pt-2 pb-5">
            <ngb-accordion #acc="ngbAccordion" activeIds="ngb-panel-0" closeOthers="true">
              <ngb-panel id="ngb-panel-0" cardClass="border-0 rounded shadow-md mb-2">
                <ng-template ngbPanelTitle>
                  <div class="border-0 bg-light" id="headingone">
                    <h6 class="title mb-0 text-dark">How to download myJuan Rider via Play Store?</h6>
                  </div>
                </ng-template>
                <ng-template ngbPanelContent>
                  <p class="text-muted mb-0 faq-ans">1. On your smartphone, open the Play Store app and search for “Vips Rider”.</p><br>
                  <p class="text-muted mb-0 faq-ans">2. Tap download, once completed open the app, and continue with the registration.</p>
                </ng-template>
              </ngb-panel>
      
              <ngb-panel cardClass="border-0 rounded shadow-md mb-2">
                <ng-template ngbPanelTitle>
                  <div class="border-0 bg-light" id="headingthree">
                    <h6 class="title mb-0 text-dark">How to register?</h6>
                  </div>
                </ng-template>
                <ng-template ngbPanelContent>
                    <p class="text-muted mb-0 faq-ans">1. Enter your preferred 11-digit mobile number.</p><br>
                    <p class="text-muted mb-0 faq-ans">2. Verify your mobile number and enter your 6-digit OTP.</p><br>
                    <p class="text-muted mb-0 faq-ans">3. Fill out your Basic Information to verify your identity.</p><br>
                    <p class="text-muted mb-0 faq-ans">4. Set up your 4-digit MPIN code to protect your account.</p><br>
                    <p class="text-muted mb-0 faq-ans">5. Scan/Upload the QR CODE or enter the reference code of your preferred JuanPay outlet to link your account.</p>
                </ng-template>
              </ngb-panel>

              <ngb-panel cardClass="border-0 rounded shadow-md mb-2">
                <ng-template ngbPanelTitle>
                  <div class="border-0 bg-light" id="headingthree">
                    <h6 class="title mb-0 text-dark">How to complete rider registration?</h6>
                  </div>
                </ng-template>
                <ng-template ngbPanelContent>
                    <p class="text-muted mb-0 faq-ans">1. Tap the “Apply Now!” button located on your myJuan Rider dashboard screen.</p><br>
                    <p class="text-muted mb-0 faq-ans">2. Tap “Begin” button to view Terms and Conditions.</p><br>
                    <p class="text-muted mb-0 faq-ans">3. Read and tap “Agree and Continue” button to proceed to email validation.</p><br>
                    <p class="text-muted mb-0 faq-ans">4. Enter a valid email address and tap “Verify” button.</p><br>
                    <p class="text-muted mb-0 faq-ans">5. Enter a valid 6-digit email OTP to verify your email address.</p><br>
                    <p class="text-muted mb-0 faq-ans">6. Fill out all required fields in the User Information screen and tap “Next” button once done.</p><br>
                    <p class="text-muted mb-0 faq-ans">7. Tap “Begin” button on the License Verification screen</p><br>
                    <p class="text-muted mb-0 faq-ans">8. Upload your Driver’s License front and back photo and tap “Next” button to continue.</p><br>
                    <p class="text-muted mb-0 faq-ans">9. Upload your Selfie and Selfie with ID photo and tap “Next” button to continue.</p><br>
                    <p class="text-muted mb-0 faq-ans">11. Upload your valid Vehicle’s OR&CR, NBI Clearance, Drug Test Clearance (Optional), Vaccination Card, and Vehicle Photo, and tap “Next” button</p><br>
                    <p class="text-muted mb-0 faq-ans">12. Wait for our Customer Experience Team to approve your request within 48 hours.</p><br>
                    <p class="text-muted mb-0 faq-ans">13. Tap “Go to dashboard” button to complete your application request.</p>
                </ng-template>
              </ngb-panel>

              <ngb-panel cardClass="border-0 rounded shadow-md mb-2">
                <ng-template ngbPanelTitle>
                  <div class="border-0 bg-light" id="headingtwo">
                    <h6 class="title mb-0 text-dark">Rider registration requirements</h6>
                  </div>
                </ng-template>
                <ng-template ngbPanelContent>
                  <p class="text-muted mb-0 faq-ans">1. Vehicle’s OR & CR</p>
                  <p class="text-muted mb-0 faq-ans">2. NBI Clearance</p>
                  <p class="text-muted mb-0 faq-ans">3. Drug Test Clearance (Optional)</p>
                  <p class="text-muted mb-0 faq-ans">4. Vaccination Card</p>
                  <p class="text-muted mb-0 faq-ans">5. Vehicle Photo (Front, Side and Back)</p>

                </ng-template>
              </ngb-panel>

              <ngb-panel cardClass="border-0 rounded shadow-md mb-2">
                <ng-template ngbPanelTitle>
                  <div class="border-0 bg-light" id="headingthree">
                    <h6 class="title mb-0 text-dark">How to set up your profile?</h6>
                  </div>
                </ng-template>
                <ng-template ngbPanelContent>
                    <p class="text-muted mb-0 faq-ans">1. Top up your VIPCo wallet minimum of 500.00.</p><br>
                    <p class="text-muted mb-0 faq-ans">2. Watch the virtual training until its completion. (Under Development)</p><br>
                    <p class="text-muted mb-0 faq-ans">3. Order your gears from the head office (2pcs Uniform, myJuan Rider ID, Delivery Bag, Helmet (Optional), Decals).</p><br>
                    <p class="text-muted mb-0 faq-ans">4. Tap “Complete” button to complete your profile set up.</p><br>
                </ng-template>
              </ngb-panel>

              <ngb-panel cardClass="border-0 rounded shadow-md mb-2">
                <ng-template ngbPanelTitle>
                  <div class="border-0 bg-light" id="headingthree">
                    <h6 class="title mb-0 text-dark">Virtual Training Outline</h6>
                  </div>
                </ng-template>
                <ng-template ngbPanelContent>
                    <p class="text-muted mb-0 faq-ans">1. Food Safety Training</p><br>
                    <p class="text-muted mb-0 faq-ans">2. myJuan Rider platform Technical Training</p><br>
                    <p class="text-muted mb-0 faq-ans">3. Emergency Training</p><br>
                    <p class="text-muted mb-0 faq-ans">4. Vehicle Maintenance Training</p><br>
                    <p class="text-muted mb-0 faq-ans">5. Covid-19 Safety Training</p><br>
                    <p class="text-muted mb-0 faq-ans">6. Scam / Fraud Training</p><br>
                </ng-template>
              </ngb-panel>

              <ngb-panel cardClass="border-0 rounded shadow-md mb-2">
                <ng-template ngbPanelTitle>
                  <div class="border-0 bg-light" id="headingthree">
                    <h6 class="title mb-0 text-dark">How to view your QR code?</h6>
                  </div>
                </ng-template>
                <ng-template ngbPanelContent>
                    <p class="text-muted mb-0 faq-ans">1. Login to your myJuan Super app.</p><br>
                    <p class="text-muted mb-0 faq-ans">2. Login to your myJuan Super app.</p><br>
                </ng-template>
              </ngb-panel>

              <ngb-panel cardClass="border-0 rounded shadow-md mb-2">
                <ng-template ngbPanelTitle>
                  <div class="border-0 bg-light" id="headingthree">
                    <h6 class="title mb-0 text-dark">How to Top Up?</h6>
                  </div>
                </ng-template>
                <ng-template ngbPanelContent>
                    <p class="text-muted mb-0 faq-ans">1. Tap the “TOP-UP” icon located on your myJuan dashboard screen.</p><br>
                    <p class="text-muted mb-0 faq-ans">2. Select “OVER-THE-COUNTER” from the list.</p><br>
                    <p class="text-muted mb-0 faq-ans">3. Enter an “AMOUNT” then tap the “REQUEST” button.</p><br>
                    <p class="text-muted mb-0 faq-ans">4. Confirm your transaction by sliding the button to the right.</p><br>
                    <p class="text-muted mb-0 faq-ans">5. To complete the transaction, tap the "SAVE RECEIPT" button and then the close (X) button.</p><br>
                    <p class="text-muted mb-0 faq-ans">6. SHOW RECEIPT to our JuanPay Outlet teller located on the ground floor.</p><br>
                </ng-template>
              </ngb-panel>

              <ngb-panel cardClass="border-0 rounded shadow-md mb-2">
                <ng-template ngbPanelTitle>
                  <div class="border-0 bg-light" id="headingthree">
                    <h6 class="title mb-0 text-dark">How to send/pay using the app?</h6>
                  </div>
                </ng-template>
                <ng-template ngbPanelContent>
                    <p class="text-muted mb-0 faq-ans">1. Tap the “SEND” icon located on your myJuan dashboard screen.</p><br>
                    <p class="text-muted mb-0 faq-ans">2. Select “SCAN QR CODE” from the list.</p><br>
                    <p class="text-muted mb-0 faq-ans">3. Give myJuan permission to access your camera/gallery/pictures/video.</p><br>
                    <p class="text-muted mb-0 faq-ans">4. Scan/upload the store’s unique QR code to capture their account.</p><br>
                    <p class="text-muted mb-0 faq-ans">5. Type in the "AMOUNT" you must send as payment, then tap the “NEXT” button.</p><br>
                    <p class="text-muted mb-0 faq-ans">6. Confirm your transaction by sliding the button to the right.</p><br>
                    <p class="text-muted mb-0 faq-ans">7. To complete the transaction, tap the "SAVE RECEIPT" button and then the close (X) button.</p><br>
                    <p class="text-muted mb-0 faq-ans">8. Verify with the staff at the store whether your payment was accepted.</p><br>
                    <p class="text-muted mb-0 faq-ans">9. Claim your purchased goods.</p><br>                   
                </ng-template>
              </ngb-panel>

              <ngb-panel cardClass="border-0 rounded shadow-md mb-2">
                <ng-template ngbPanelTitle>
                  <div class="border-0 bg-light" id="headingthree">
                    <h6 class="title mb-0 text-dark">How to send money to another myJuan Member using the app?</h6>
                  </div>
                </ng-template>
                <ng-template ngbPanelContent>
                    <p class="text-muted mb-0 faq-ans">1. Tap the “SEND” icon located on your myJuan dashboard screen.</p><br>
                    <p class="text-muted mb-0 faq-ans">2. Select “SEND MONEY TO ANOTHER VIPS MEMBER” from the list.”.</p><br>
                    <p class="text-muted mb-0 faq-ans">3. Please enter the recipient's 11-digit mobile number and press submit button.</p><br>
                    <p class="text-muted mb-0 faq-ans">4. Type in the "AMOUNT" you will send, then tap the “NEXT” button.</p><br>
                    <p class="text-muted mb-0 faq-ans">5. Confirm your transaction by sliding the button to the right.</p><br>
                    <p class="text-muted mb-0 faq-ans">6. To complete the transaction, tap the "SAVE RECEIPT" button and then the close (X) button.</p><br>
                </ng-template>
              </ngb-panel>

              <ngb-panel cardClass="border-0 rounded shadow-md mb-2">
                <ng-template ngbPanelTitle>
                  <div class="border-0 bg-light" id="headingthree">
                    <h6 class="title mb-0 text-dark">How to send money to another Bank/E-wallet?</h6>
                  </div>
                </ng-template>
                <ng-template ngbPanelContent>
                    <p class="text-muted mb-0 faq-ans">1. Tap the “SEND” icon located on your myJuan dashboard screen.</p><br>
                    <p class="text-muted mb-0 faq-ans">2. Select “VIP CLOUD TRANSFER” from the list.</p><br>
                    <p class="text-muted mb-0 faq-ans">3. Select which bank or e-wallet you want to transfer your money.</p><br>
                    <p class="text-muted mb-0 faq-ans">4. Type in the AMOUNT and other required information, then tap the “NEXT” button.</p><br>
                    <p class="text-muted mb-0 faq-ans">5. Confirm your transaction by sliding the button to the right.</p><br>
                    <p class="text-muted mb-0 faq-ans">6. To complete the transaction, tap the "SAVE RECEIPT" button and then the close (X) button.</p><br>
                    
                </ng-template>
              </ngb-panel>

              <ngb-panel cardClass="border-0 rounded shadow-md mb-2">
                <ng-template ngbPanelTitle>
                  <div class="border-0 bg-light" id="headingthree">
                    <h6 class="title mb-0 text-dark">How to cash out your funds using the app?</h6>
                  </div>
                </ng-template>
                <ng-template ngbPanelContent>
                    <p class="text-muted mb-0 faq-ans">1. Tap the “CASH OUT” icon located on your myJuan dashboard screen.</p><br>
                    <p class="text-muted mb-0 faq-ans">2. Tap and select “VIA JuanPay OUTLET”.</p><br>
                    <p class="text-muted mb-0 faq-ans">3. Enter the “AMOUNT” and tap the “CONTINUE” button.</p><br>
                    <p class="text-muted mb-0 faq-ans">4. Confirm your transaction by sliding the button to the right.</p><br>
                    <p class="text-muted mb-0 faq-ans">5. To complete the transaction, tap the "SAVE RECEIPT" button and then the close (X) button.</p><br>

                </ng-template>
              </ngb-panel>

              <ngb-panel cardClass="border-0 rounded shadow-md mb-2">
                <ng-template ngbPanelTitle>
                  <div class="border-0 bg-light" id="headingthree">
                    <h6 class="title mb-0 text-dark">How to turn on/off your Rider Delivery status?</h6>
                  </div>
                </ng-template>
                <ng-template ngbPanelContent>
                    <p class="text-muted mb-0 faq-ans">1. On your dashboard, tap the rider icon toggle switch to on/off to change your status.</p><br>
                </ng-template>
              </ngb-panel>

              <ngb-panel cardClass="border-0 rounded shadow-md mb-2">
                <ng-template ngbPanelTitle>
                  <div class="border-0 bg-light" id="headingthree">
                    <h6 class="title mb-0 text-dark">How to view your VIPCo Balance?</h6>
                  </div>
                </ng-template>
                <ng-template ngbPanelContent>
                    <p class="text-muted mb-0 faq-ans">1. Login to your myJuan account.</p><br>
                    <p class="text-muted mb-0 faq-ans">2. View your VIPCo balance from the dashboard screen.</p><br>
                </ng-template>
              </ngb-panel>

              <ngb-panel cardClass="border-0 rounded shadow-md mb-2">
                <ng-template ngbPanelTitle>
                  <div class="border-0 bg-light" id="headingthree">
                    <h6 class="title mb-0 text-dark">How to view the app's Terms and Conditions?</h6>
                  </div>
                </ng-template>
                <ng-template ngbPanelContent>
                    <p class="text-muted mb-0 faq-ans">1. Tap the “ACCOUNT” button on the dashboard's bottom part.</p><br>
                    <p class="text-muted mb-0 faq-ans">2. Tap “TERMS & CONDITIONS / PRIVACY” to view the app’s terms & conditions and privacy policy.</p><br>

                </ng-template>
              </ngb-panel>

              <ngb-panel cardClass="border-0 rounded shadow-md mb-2">
                <ng-template ngbPanelTitle>
                  <div class="border-0 bg-light" id="headingthree">
                    <h6 class="title mb-0 text-dark">How to view About app?</h6>
                  </div>
                </ng-template>
                <ng-template ngbPanelContent>
                    <p class="text-muted mb-0 faq-ans">1. Tap the account icon at the bottom of the dashboard to open the account profile screen.</p><br>
                    <p class="text-muted mb-0 faq-ans">2. Tap “ABOUT APP” to view the app information.</p><br>
                </ng-template>
              </ngb-panel>

              <ngb-panel cardClass="border-0 rounded shadow-md mb-2">
                <ng-template ngbPanelTitle>
                  <div class="border-0 bg-light" id="headingthree">
                    <h6 class="title mb-0 text-dark">How to Sign Out from the app?</h6>
                  </div>
                </ng-template>
                <ng-template ngbPanelContent>
                    <p class="text-muted mb-0 faq-ans">1. Tap the account icon at the bottom of the dashboard to open the account profile screen.</p><br>
                    <p class="text-muted mb-0 faq-ans">2. Tap “SIGN OUT” and tap “YES” button to completely sign out from the app.</p><br>
                </ng-template>
              </ngb-panel>

              <ngb-panel cardClass="border-0 rounded shadow-md mb-2">
                <ng-template ngbPanelTitle>
                  <div class="border-0 bg-light" id="headingthree">
                    <h6 class="title mb-0 text-dark">How to view your linked QR code?</h6>
                  </div>
                </ng-template>
                <ng-template ngbPanelContent>
                    <p class="text-muted mb-0 faq-ans">1. Tap the account icon at the bottom of the dashboard to open the account profile screen.</p><br>
                    <p class="text-muted mb-0 faq-ans">2. Tap the QR code on the top right corner to view details of your linked JuanPay Outlet.</p><br>
                </ng-template>
              </ngb-panel>

              <ngb-panel cardClass="border-0 rounded shadow-md mb-2">
                <ng-template ngbPanelTitle>
                  <div class="border-0 bg-light" id="headingthree">
                    <h6 class="title mb-0 text-dark">How to reset your Mobile Pin code (MPIN)?</h6>
                  </div>
                </ng-template>
                <ng-template ngbPanelContent>
                    <p class="text-muted mb-0 faq-ans">1. Tap “Forgot MPIN” on the login screen.</p><br>
                    <p class="text-muted mb-0 faq-ans">2. Tap “CONTINUE” button to reset your MPIN</p><br>
                    <p class="text-muted mb-0 faq-ans">3. Input a valid 6-digit OTP sent to your mobile number.</p><br>
                    <p class="text-muted mb-0 faq-ans">4. Enroll your new 4-digit MPIN</p><br>
                    <p class="text-muted mb-0 faq-ans">5. Re-enter your enrolled 4-digit MPIN</p><br>
                    <p class="text-muted mb-0 faq-ans">6. Tap “DONE” button to complete your setup..</p><br>

                </ng-template>
              </ngb-panel>

              <ngb-panel cardClass="border-0 rounded shadow-md mb-2">
                <ng-template ngbPanelTitle>
                  <div class="border-0 bg-light" id="headingthree">
                    <h6 class="title mb-0 text-dark">Troubleshooting Steps</h6>
                  </div>
                </ng-template>
                <ng-template ngbPanelContent>
                    <p class="text-muted mb-0 faq-ans">Step 1:  If you experience any technical issues, please quit the app and launch it again to see if the technical problem has been fixed.</p><br>
                    <p class="text-muted mb-0 faq-ans">Step 2:  Alternatively, you can contact our IT staff to discuss any technical issues you may have by sending an email to <a href="mailto:it@acmbusinesssolutions.net" class="text-foot list-unstyled">it@acmbusinesssolutions.net</a>.</p><br>
                </ng-template>
              </ngb-panel>

            </ngb-accordion>
          </div>
        </div>

        
        <!--end col-->
      </div>
      <!--end row-->
    </div>
    <!--end container-->
    <div class="container pb-5">
        <ul class="list-unstyled text-muted">
            <i-feather name="arrow-left-circle" class="fea icon-sm text-primary me-2"></i-feather>
            <a href="#">Go Back to Homepage</a>
        </ul>

    </div>
  </section>

 

  <app-footer></app-footer> 